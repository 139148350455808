
.ColorEditButton {
	button {
		background: var(--item-color);
		color: var(--text-color);
		border-radius: .25rem;
		padding: .125rem .25rem;
		// margin: 0 3.125rem 0 0rem;
		margin: .5rem 0 .33rem;
		width: 100%;
		text-transform: none;

		&:hover, &:focus, &:active {
			background: var(--item-color);
		}
	}
}
.ColorEditButtonDialog {
	:global(.MuiDialogTitle-root) {
		padding-bottom: 0; //.125rem;
	}

	:global(.MuiDialogContent-root) {
		padding: 0 !important;
	}
}