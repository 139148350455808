@import '../pages/styles/layoutCommon.scss';

.root {
	margin: 0;

	color: $foreground-color;

	// position: relative;
	// height: 100%;
	// min-height: 100%;

	position: fixed;
	top: 2.25rem;
	left: 0;
	right: 0;
	bottom: 3.5rem;

	transition: bottom $default-transition-time $my-cubic-transition,
		top $default-transition-time $my-cubic-transition;

	width: 100%;
	max-width: 640px;
	margin: auto;

	$split: 50%;

	display: flex;
	flex-direction: column;
	align-items: center;

	.hint {
		position: fixed;
		z-index: 5599;
		left: 50%;
		top: 50%;
		
		opacity: 0;
		pointer-events: none;
		transform: translate(-50%, -50%) scale(.5);

		// transform: translate(-50%, -50%);

		box-shadow: 0 0 3px rgba(0,0,0,1),
			0 0 5px rgba(0,0,0,0.9),
			0 0 7px rgba(0,0,0,0.8),
			0 0 10px rgba(0,0,0,0.7),
			0 0 20px rgba(0,0,0,0.6);

		width: 10rem;
		max-width: 90%;

		@include transition-all(.125s);

		background: rgba(255,255,255,1);
		padding: .75rem .5rem .75rem;
		border-radius: 0.25rem;
		font-size: 1.5rem;
		color: black;
		font-family: $serif-font;
		line-height: 1.1;

		text-align: center;

		label {
			font-size: .625rem;
			opacity: 0.625;
			margin: .25rem 0;
			font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
			display: block;
		}

		&.error {
			background: rgba(255,255,0,0.925);
		}

		&.open {
			pointer-events: all;
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}
	}

	.question {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: $split;
		overflow: auto;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		// Set in javascript if question overflows via
		// ref on our DOM node and a React.useEffect()
		&.overflows {
			justify-content: flex-start;
		}

		
		

		.verseRef {
			opacity: 0.8;
			font-size: .75rem;
			margin: .25rem 0;// .75rem;
			min-width: 10rem;
			width: 100%;
		}

		.content {
			padding: .5rem;
			font-family: $serif-font;
			font-weight: 400;

			.ref,
			.bottomRef {
				text-align: center;
				font-weight: 700;
				margin: -.5rem 0 .25rem;

				.text {
					position: relative;

					.circles {
						position: absolute;
						right: -1rem;
						top: 50%;
						transform: translateY(-50%);

						:global(.CircleProgress-root) {
							// color: white;
		
							circle:not(:global(.CircleProgress-bar)) {
								stroke: rgba(255,255,255,0.25);
							}
						}
					}
				}
			}

			.bottomRef {
				margin: .25rem 0 .5rem;
				opacity: 0.8;
			}
		}

		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, .5) 0%,
			rgba(0, 0, 0, 1) .5rem,
			rgba(0, 0, 0, 1) 95%,
			rgba(0, 0, 0, 0) 100%);


		mask-image: $mask;
		-webkit-mask-image: $mask;

		text-align: center;

		.activeBlank {
			color: yellow;
			// font-weight: bold;
			display: inline-block;
			border-radius: 0.25rem;
			padding: 0.125rem  0.25rem;
			margin: -0.125rem -0.25rem;
			position: relative;
			outline-color: yellow;
			outline-style: dotted;
		}


		.activeText {
			color: yellow;
		}

		:global(.Proctor-attemptedAnswer) {
			background: rgba(255,255,0,0.33);
			border-radius: 0.25rem;
			padding: 0.125rem  0.25rem;
			margin: -0.125rem -0.25rem;
			position: relative;

			&:global(.--bad) {
				background: rgba(255,0,0,0.33);
				// line-height: 1.85;
			}

			&:global(.--good) {
				background: rgba(0,255,0,0.33);
			}

			:global(.--got-word) {
				display: inline-block;
				opacity: 1;
				transform: scale(1) translateY(0);
			}

			:global(.--expected-word) {
				position: absolute;
				top: 50%;
				opacity: 0;
				font-size: .9rem;
				left: 50%;
				transform: translateX(-50%);
				font-weight: bold;
			}

			// :global(.--got-word) {
			// 	display: inline-block;

			// 	font-weight: 200;
			// 	text-decoration: line-through;
			// 	opacity: 0.8;

			// 	transform: scale(0.75) translateY(.25rem);
			// }

			// :global(.--expected-word) {
			// 	position: absolute;
			// 	top: -.5rem;
			// 	font-size: .9rem;
			// 	left: 50%;
			// 	transform: translateX(-50%);
			// 	font-weight: bold;
			// }
		}
	}

	&:global(.FILL_IN_BLANKS) {
		.question {
			font-size: 1.0rem;
			line-height: 1.2;
			font-weight: 600;
		}
	}

	.answers {
		position: absolute;
		top: (100% - $split);
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;

		padding-top: .25rem;
		padding-bottom: .75rem;
		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) .5rem,
			rgba(0, 0, 0, 1) 95%,
			rgba(0, 0, 0, 0) 100%);

		mask-image: $mask;
		-webkit-mask-image: $mask;
		

		// display: flex;
		// flex-direction: column;
		// justify-content: flex-start;
		// align-items: center;

		.content {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
		}

		.keyboardBox {
			margin: auto .5rem;
			width: calc(100% - 1rem);
			// padding: 0 .25rem;
			box-sizing: border-box;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			
			.textAnswer {
				margin-right: .125rem;
				margin-top: -.25rem;
				margin-bottom: .25rem;

				textarea {
					font-size: .75rem;
					line-height: 1.02;
				}

				:global(.MuiFormHelperText-root) {
					color: rgba(255,255,255,0.625);
					font-size: .625rem;
					line-height: 1;
					margin-top: .33rem;
				}

				:global(.MuiIconButton-root) {
					padding: 12px 6px 12px 3px;

					& + button {
						padding: 12px 0px 12px 3px;
						transform: translateY(-2px);
					}
				}
			}

			button {
				text-transform: none;
				font-size: .625rem;
				text-align: left;
				justify-content: flex-start;

				.label {
					opacity: 0.625;
				}

				svg {
					margin-right: .25rem;
				}
			}

			button[disabled] {
				opacity: 0.33;
			}

			.highlight {
				svg {
					fill: lighten($highlight-color, 10%);
					filter: drop-shadow(0 0 1rem rgba(255,255,255,0.625));
				}
			}

			:global(.MuiInput-underline):after {
				border-bottom-color: lighten($highlight-color, 10%);
			}
		}
		

		.answerButton {
			&, &:focus, &:active, &:hover {
				background: rgba(255,255,255,0.125);
				color: rgba(255,255,255,0.95);
				font-weight: 600;
				outline: none;
				border: 0;
				box-shadow: none;
			}

			border-radius: 0.25rem;

			font-size: .75rem;

			margin: 0.125rem 0.125rem;
			padding: 0.125rem .125rem;

			min-width: 5rem;

			text-transform: none;
			
			&.bad, 
			&.bad:focus, &.bad:active, &.bad:hover {
				background: rgba(255,0,0,0.33);
			}

			&.good, 
			&.good:focus, &.good:active, &.good:hover {
				background: rgba(0,255,0,0.33);
			}

			&.used, 
			&.used:focus, &.used:active, &.used:hover {
				background: rgba(255,255,0,0.33);
				// color: rgba(0,0,0,0.75);
			}

			// Only show keyboard hints at higher screen sizes
			// @media (min-width: 640px) {
			// 	position: relative;

			// 	// This just renders the "data-keynum"
			// 	// attribute in a small font below the possible answer
			// 	&::after {
			// 		content: attr(data-keynum);
			// 		display: block;
			// 		position: absolute;
			// 		opacity: 0.4;
			// 		font-size: .625rem;
			// 		bottom: 0.025rem;
			// 		right: 0.25rem;
			// 		// left: 50%;
			// 		// transform: translateX(-50%);
			// 	}
			// }
		}
	}

	// This block of four selectors sets up the "before" state for our results
	// animation. Set the starting positions, so to speak.
	.result,
	.resultClose,
	.question,
	.attemptResults,
	.answers {
		// transform-style: preserve-3d;
		&, .content, :global(.Proctor-attemptedAnswer) {
			transition: all .15s cubic-bezier(0.425, -0.600, 0.700, 1.520);
			//.125s cubic-bezier(0.55, 0.085, 0.68, 0.53);
		}
	}

	.result {
		// opacity: 0;
		transform: scale(10) translateY(-150%);
		color: green;

		pointer-events: none;	
	}

	

	.resultClose {
		pointer-events: none;
		position: fixed;
		bottom: 3.5rem;
		top: 70%;
		width: 75%;
		max-width: 600px;

		opacity: 0;
		transform: scale(.75) translateY(-100%);

		border-radius: 1.5rem;


		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		
		.fab {
			background: $highlight-color;
			color: white;

			transform-origin: center center;

			// box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;

			box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 
				0px 6px 10px 0px rgba(0,0,0,0.2), 
				0px 1px 18px 0px rgba(0,0,0,0.15);

			width: 100%;
			height: auto;
			padding-top: .35rem;
			padding-bottom: .35rem;

			label {
				margin: 0;
				padding: 0;
				text-transform: none;
			}

			pointer-events: none;

			
		}

		// Overwrite some styles above to use a pulse animation
		.fab:not(.resultsLoading) {
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
			// transform: scale(1) translate(-50%, 0%);
			animation: pulse_follow_fab 2s infinite;
		}

		.fab.resultsLoading {
			opacity: 0.33;
			filter: saturate(0.9);
			pointer-events: none;
		}


		$pulse-color: lighten(transparentize($highlight-color, .5), 25%);
		@keyframes pulse_follow_fab {
			0% {
				transform: scale(0.95);// translate(-50%, 0%);
				box-shadow: 0 0 0 0 transparentize($pulse-color, .7);
			}

			70% {
				transform: scale(1);// translate(-50%, 0%);
				box-shadow: 0 0 0 10px transparentize($pulse-color, 0);
			}

			100% {
				transform: scale(0.95);// translate(-50%, 0%);
				box-shadow: 0 0 0 0 transparentize($pulse-color, 0);
			}
		}
	}

	.attemptResults {
		// pointer-events: none;
		position: fixed;
		// bottom: 9.5rem;
		bottom: 0;

		z-index: 1101;
		
		left: 0;
		right: 0;
		height: auto;
		min-height: 3.5rem;
		// background: transparentize($highlight-color, .33);
		background: $highlight-color;
		// background: rgba(255,255,255,0.777);
		// color: black;
		color: white;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		

		.content {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			width: 100%;
			max-width: 560px;

			padding: .25rem;

			// box-shadow: 0 0 3px rgba(0,0,0,0.8),
			// 	// 0 0 5px rgba(0,0,0,0.9),
			// 	// 0 0 7px rgba(0,0,0,0.8),
			// 	0 0 10px rgba(0,0,0,0.7),
			// 	0 0 20px rgba(0,0,0,0.6);

			// box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

			.RenderAttemptResult {

				margin: 0 .5rem;
				padding: 0.25rem 0 .25rem 0;
				text-align: center;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				&.accuracy,
				&.comprehension,
				&.difficulty,
				&.badgeLevel {
					// margin: 0;
					opacity: 1;
				}

				.title {
					font-size: .5rem;
					// font-weight: bold;
					opacity: 0.75;
				}

				.value {
					font-size: .825rem;
					font-weight: bold;
				}

				.change {
					font-size: .5rem;
					position: relative;

					&::before {
						content: attr(data-val);
						display: block;
						pointer-events: none;

						position: absolute;
						// top: -1rem;
						top: 0;
						// right: 1rem;
						left: 50%;
						transform: translateX(-50%);

						font-size: .5rem;

						transition: transform 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);

						// color: green;
					}



				}

				&[data-up="up"] {
					// .change, 
					.change::before {
						color: green;
					}
				}

				&[data-up="down"] {
					// .change, 
					.change::before {
						color: red;
					}
				}

				&.difficulty {
					&[data-up="up"] {
						// .change, 
						.change::before {
							color: red;
						}
					}
	
					&[data-up="down"] {
						// .change, 
						.change::before {
							color: green;
						}
					}
				}
			}
		}

		&.animResults {
			.RenderAttemptResult {
				.change::before {
					// opacity: 1;

					// transition: transform 1.5s $my-cubic-transition;
					

					// transform: translateX(-50%) translateY(-15rem) scale(10);
					// opacity: 0;

					transform: scale(10) translateY(-5rem);
					// opacity: 0;
				}
			}
		}

	}

	// Hang a backdrop of bad or good color off the root
	&::after {
		content: ' ';
		display: block;

		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0,255,0,0.625);

		opacity: 0;

		
	}

	&.resultBad::after {
		background: rgba(255,0,0,0.2);
	}

	&.resultBad {
		.result {
			color: red;		
		}
	}

	// This slector now transitions the props to their "in" state,
	// animating them to their final position to display results
	&.resultMode {
		cursor: pointer;
		
		&::after {
			animation: pulse_bg .5s;
			animation-delay: .05s;
			pointer-events: none;

			@keyframes pulse_bg {
				0% {
					opacity: 0;
				}

				40% {
					opacity: 1;
				}

				100% {
					opacity: 0;
				}
			}
		}

		.result {
			transform: scale(4) translateY(-50%);

			// // At tablet size, Material UI does some DPI changes
			// // which necessitate resizing and repositioning of this icon
			// @media (min-width: 768px) {
			// 	& {
			// 		transform: scale(3) translateY(40%);
			// 	}	
			// }
		}

		// Bring in our button
		.resultClose {
			pointer-events: all;
			opacity: 1;
			transform: scale(1) translateY(0);

			.fab {
				pointer-events: all;
			}
		}
		
		// Center the question
		.question {
			// pointer-events: none;
			top: 0;
			bottom: 0;
			// justify-content: center !important;
			padding: 6rem 0;

			.content {
				// transform: translateY(50%) scale(1.5);
				// transform: scale(0.8, 1.75);
				// width: calc(50% - .25rem);
				// font-size: 1.5rem !important;

				:global(.Proctor-attemptedAnswer) {
					&:global(.--bad) {
						line-height: 1.85;
					}
		
					:global(.--got-word) {
						font-weight: 200;
						text-decoration: line-through;
						opacity: 0.8;
		
						transform: scale(0.75) translateY(.25rem);
					}
		
					:global(.--expected-word) {
						top: -.5rem;
						opacity: 1;
					}
				}
			}
		}

		// Hide the answers
		.answers {
			transform: translateY(110%) scale(0.5);
		}
	}	

	.nextButton {
		
		&, &:focus, &:active, &:hover {
			// background: rgba(255,255,255,0.125);
		}

		padding: 1rem 3rem;
		margin: 2rem;
		color: white;

		border: 0;
		&, &:active, &:hover, &:focus {
			outline: none;
		}

		svg {
			transform: scale(3.33);
		}

	}


	@media screen and (max-height: 400px) {
		& {
			top: 0;
			bottom: 0;
		}
	}


	&.keyboardMode {

		$maxKeyboardHeight: 4.125rem;

		.question {
			bottom: $maxKeyboardHeight;
		}

		.answers {
			
			top: calc(100% - #{$maxKeyboardHeight});
			padding: .5rem 0 0;

			.content {
				transform: translateY(100%);
				opacity: 0;
			}
		}
		
	}
}



body:global(.notched) {
	.verseBox {
		top: $notch-size + 2.5rem;
	}
}