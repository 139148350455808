@import '../styles/layoutCommon.scss';


.root.variantSmall {
	padding: 0 0;
	margin: .25rem .125rem 0 .125rem;

	&.verse {
		margin-bottom: .75rem;
	}

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	$width: 5rem;
	width: $width;

	// border-radius: .25rem;
	// border-radius: 4px;
	border-radius: 0;

	--bar-color: white;
	
	:global(.MuiCard-root) {
		border-radius: 0;
		box-shadow: none;
	}

	:global(.CircleProgress-root) {
		:global(.CircleProgress-bar) {
			stroke-width: 3px;
		}
	}

	.outerCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(#c35256, 20%);
		}
	}

	.outerCircle2 {
		:global(.CircleProgress-bar) {
			stroke: lighten(#5b822b, 20%);
		}
	}

	.progressCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(white, 20%);
		}
	}

	.badgeCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(#5b822b, 20%);
		}
	}


	.letter {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		// border: 1px solid white;
		border-radius: 50%;
		$size: ($width * 9/16) * .25;
		font-size: $size;
		font-weight: 700;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: $size + 1rem;
		height: $size + 1rem;
	}

	.media {
		width: $width;
		height: $width * 9/16;

		// background-color: var(--cover-color);


		// z-index: 1;
		background-size: cover;
		background-position: center center;

		background-image: none !important;
		
		background-color: var(--pack-color);
	}

	.progressPercent {
		display: none;

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		
		height: 4px;
		background: rgba(0,0,0,0.75);
		// box-shadow: 0 0 3px rgba(0,0,0,.6);

		&::before {
			content: ' ';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 0px;
			width: var(--progress-percent, 0%);
			border: 2px solid var(--bar-color, red);
			border-radius: 1.5px;
			// box-shadow: 0 0 3px rgba(0,0,0,.6);
			transform: translateX(-2px);
		}


		// &::after {
		// 	$size: 8px;
		// 	content: ' ';
		// 	display: block;
		// 	position: absolute;
		// 	width: $size;
		// 	height: $size;
		// 	border-radius: 50%;
		// 	background: var(--bar-color, rgb(250, 244, 244));

		// 	bottom: -$size / 2;
		// 	// Can't use scss vars in calc() - arggg
		// 	left: calc(var(--progress-percent, 0%) - 8px/2);
		// 	// box-shadow: 0 0 3px rgba(0,0,0,.6);
		// }
	}

	.ref {
		width: $width;
		height: $width * 9/16;

		font-family: $serif-font;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		background: $highlight-color;
		color: white;
		line-height: 1;
		letter-spacing: -.5px;
		font-size: .75rem;

		padding: 0.125rem;
	}

	.media,
	.ref {
	// & {
		// Based on customIgOverlayLight mixin
		// @include customIgOverlayLight();
		@include customIgOverlayDark();

		// Modify customIgOverlayLight mixin
		&::after {
			position: absolute;
			opacity: 0.33;
			z-index: 100;
		}

		// Make the overlay brighter for verses
		&.verse::after {
			opacity: 0.625;
			z-index: 100;
		}
	}

	.name {
		color: $foreground-color;
		font-size: .625rem;
		line-height: .95;
		margin: .175rem 0 0 ;
		text-align: center;
		width: $width;
	}
	
	// &.verse {
		.name {
			$mask: linear-gradient(to bottom,
				rgba(0, 0, 0, 1) 0%,
				rgba(0, 0, 0, 1) 70%,
				rgba(0, 0, 0, 0) 100%);

			mask-image: $mask;
			-webkit-mask-image: $mask;

			height: 1.25rem;
			font-size: .5rem;
			// text-align: left;

		}
	// }

	:global(.MuiCard-root) {
		position: relative;
	}

	// Design based on https://codepen.io/eode9/pen/twkKm
	.badgeText {
		// position: absolute;
		// top: 1;
		// color: white;
		// z-index: 10;

		width: $width;
		// background: #e43;
		// background: var(--cover-color, rgba(0, 0, 0, 0.75));
		background: rgba(0, 0, 0, 0.75);

		position: absolute;
		top: 25px;
		left: -50px;
		
		text-align: center;
		letter-spacing: 1px;
		
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: .45rem;
		padding-top: .05em;
		font-weight: 600;

		// color: var(--cover-text, #f0f0f0);
		color: #f0f0f0;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);


		// .corner-ribbon.shadow{
		// box-shadow: 0 0 3px var(--cover-shade, rgba(0,0,0,.3));
		box-shadow: 0 0 3px rgba(0,0,0,.3);


		// .corner-ribbon.top-right{
		top: .225rem;
		right: -1.75rem; //$width * .77;
		left: auto;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);

		cursor: inherit;

		label {
			cursor: inherit;

			margin: 0;
			padding: 0;
		}
	}

}

.root.variantSmall_old {
	padding: 0 0;
	margin: .25rem .125rem 0 .125rem;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	$width: 5.25rem;
	width: $width;

	border-radius: .25rem;

	--bar-color: white;


	.media {
		width: $width;
		height: $width * 9/16;

		background-color: var(--cover-color);

		// position: absolute;
		// top: 0;
		// left: 0;
		// right: 0;
		// bottom: 0;

		// z-index: 1;
		background-size: cover;
		background-position: center center;

	}

	.progressPercent {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		
		height: 4px;
		background: rgba(0,0,0,0.75);
		box-shadow: 0 0 3px rgba(0,0,0,.6);

		&::before {
			content: ' ';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 0px;
			width: var(--progress-percent, 0%);
			border: 2px solid var(--bar-color, red);
			border-radius: 1.5px;
			box-shadow: 0 0 3px rgba(0,0,0,.6);
		}


		&::after {
			$size: 8px;
			content: ' ';
			display: block;
			position: absolute;
			width: $size;
			height: $size;
			border-radius: 50%;
			background: var(--bar-color, red);

			bottom: -$size / 2;
			// Can't use scss vars in calc() - arggg
			left: calc(var(--progress-percent, 0%) - 8px/2);
			// box-shadow: 0 0 3px rgba(0,0,0,.6);
		}
	}

	.ref {
		width: $width;
		height: $width * 9/16;

		font-family: $serif-font;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		background: $highlight-color;
		color: white;
		line-height: 1;
		letter-spacing: -.5px;
		font-size: .75rem;

		padding: 0.125rem;
	}

	.media,
	.ref {
	// & {
		// Based on customIgOverlayLight mixin
		@include customIgOverlayLight();

		// Modify customIgOverlayLight mixin
		&::after {
			position: absolute;
			opacity: 0.33;
		}

		// Make the overlay brighter for verses
		&.verse::after {
			opacity: 0.625;
		}
	}

	.name {
		font-size: .625rem;
		line-height: .95;
		margin: .175rem 0 0 ;
		text-align: center;
		width: $width;
	}
	
	// &.verse {
		.name {
			$mask: linear-gradient(to bottom,
				rgba(0, 0, 0, 1) 0%,
				rgba(0, 0, 0, 1) 70%,
				rgba(0, 0, 0, 0) 100%);

			mask-image: $mask;
			-webkit-mask-image: $mask;

			height: 1.33rem;
			font-size: .625rem;
			// text-align: left;

		}
	// }

	:global(.MuiCard-root) {
		position: relative;
	}

	// Design based on https://codepen.io/eode9/pen/twkKm
	.badgeText {
		// position: absolute;
		// top: 1;
		// color: white;
		// z-index: 10;

		width: $width;
		// background: #e43;
		// background: var(--cover-color, rgba(0, 0, 0, 0.75));
		background: rgba(0, 0, 0, 0.75);

		position: absolute;
		top: 25px;
		left: -50px;
		
		text-align: center;
		letter-spacing: 1px;
		
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: .45rem;
		padding-top: .05em;
		font-weight: 600;

		// color: var(--cover-text, #f0f0f0);
		color: #f0f0f0;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);


		// .corner-ribbon.shadow{
		// box-shadow: 0 0 3px var(--cover-shade, rgba(0,0,0,.3));
		box-shadow: 0 0 3px rgba(0,0,0,.3);


		// .corner-ribbon.top-right{
		top: .225rem;
		right: -1.75rem; //$width * .77;
		left: auto;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);

		cursor: inherit;

		label {
			cursor: inherit;

			margin: 0;
			padding: 0;
		}
	}

}

.root.variantLarge {
	width: calc(50% - .25rem);
	margin: .125rem;

	height: 6rem;

	.actionArea {
		height: 100%;
	}

	.media {
		position: absolute;
		top: 0;
		left: 0;
		// right: $width - $height;
		right: 0;
		bottom: 0;

		z-index: 1;

		// border-radius: 0.125rem;
		// border-top-left-radius: 0.125rem;
		// border-bottom-left-radius: 0.125rem;

		background-size: cover;
		// background-image set in JSX


		// &::after {
		// 	content: ' ';
		// 	display: block;

		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	bottom: 0;
		// 	right: 0;

		// 	background: var(--cover-color);
		// 	opacity: 0.425;
		// }
	}
	.content {
		position: relative;
		z-index: 2;

		:global(.MuiTouchRipple-root) {
			z-index: 10;
		}

		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		text-shadow:  0 0 3px var(--cover-shade),
			// 0 0 5px var(--cover-shade),
			0 0 10px var(--cover-shade),
			0 0 30px var(--cover-shade);


		color: var(--cover-text, rgba(0,0,0,0.8));
		text-align: center;

		// padding: .25rem;
		padding: 0 .25rem 0 .25rem;

		.textWrap {
			.title {
				font-size: 1rem;

				font-weight: 700;
				font-size: 1rem;
				font-family: $serif-font;
				line-height: 1;
				// letter-spacing: 2px;
				margin: 0 0 .25rem 0;

				transform: translateY(-.25rem);
			}
		}
		

		.stats {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			position: absolute;
			// top: .75rem;
			// right: 1.75rem;
			bottom: 0;
			right: 0;
			left: 0;

			&.statsTop {
				top: 0;
				bottom: auto;
			}

			font-size: .5rem;

			z-index: 1;

			// padding: .25rem .125rem .25rem .625rem;
			padding: .35rem .125rem .2rem .625rem;

			// background: rgba(0, 0, 0, 0.4);
			// color: var(--cover-text, rgba(0,0,0,0.8));

			background: rgba(255,255,255,0.85);
			text-shadow: none;
			// text-shadow:  0 0 3px rgba(255,255,255,0.9),
			// 	// 0 0 5px var(--cover-shade),
			// 	0 0 10px rgba(255,255,255,0.9)
			// 	0 0 30px rgba(255,255,255,0.9);

			color: rgba(0,0,0,0.9);

			[data-stat] {
				// font-size: .5rem;
				// padding: 0 .25rem;
				// display: flex;
				// flex-direction: column;
				// align-items: center;

				// padding: .125rem .25rem;
				display: flex;
				flex-direction: column;
				align-items: center;

				line-height: .9;

				// color: rgba(0,0,0,0.5);

				// background: rgba(0,0,0,0.25);
				// // border-radius: 0.125rem;
				// border-radius: 4px; // match verses
				margin: 0 .4rem 0 0;

				// box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 
				// 		0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;


				label,
				span {
					margin: 0;
					padding: 0;
					white-space: nowrap;
				}
				span {
					font-weight: 600;
					letter-spacing: -.2px;
				}
				label {
					font-size: .4rem;
					letter-spacing: -.2px;
				}
			}
		}
	}
}

.rootOld2 {
	// $size: 7.5rem;
	// width: $size;
	// height: $size;
	// border-radius: .125rem;

	$height: 4rem;
	width: 100%;
	height: $height;

	// border: 3px solid red !important;

	// height: 0;
	// overflow: hidden;
	// // padding-top: calc(33vw / 44vw * 100%);
	// width: 33vw;
	// padding-top: 24.75vw !important;
	
	position: relative;

	background: var(--cover-color) !important;
	border-radius: 0.125rem !important;


	.media {
		position: absolute;
		top: 0;
		left: 0;
		// right: $width - $height;
		right: calc(100% - 4rem);
		bottom: 0;

		z-index: 1;

		// border-radius: 0.125rem;
		border-top-left-radius: 0.125rem;
		border-bottom-left-radius: 0.125rem;

		background-size: cover;
		// background-image set in JSX


		// &::after {
		// 	content: ' ';
		// 	display: block;

		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	bottom: 0;
		// 	right: 0;

		// 	background: var(--cover-color);
		// 	opacity: 0.425;
		// }
	}

	.textWrap {
		position: absolute;
		top: 50%;
		// left: 50%;
		// transform: translate(-50%, -50%);
		transform: translateY(-50%);
		left: $height;

		// background: rgba(255,255,255,0.5);
		// text-shadow:  0 0 .25rem var(--cover-shade),
		// 	// 0 0 10px black,
		// 	0 0 30px var(--cover-shade);

		text-shadow:  0 0 .25rem var(--cover-shade),
			0 0 10px var(--cover-shade),
			0 0 30px var(--cover-shade);
		
		color: var(--cover-text, rgba(0,0,0,0.8));
		text-align: left;

		// padding: .25rem;
		padding: 0 .25rem 0 .25rem;

		h1 {
			font-weight: 700;
			font-size: 1rem;
			// font-family: $serif-font;
			line-height: 1;
			margin: 0 0 .25rem 0;
		}

		.diz {

			display: none;

			$mask: linear-gradient(to left,
				rgba(0, 0, 0, 1) 0%,
				rgba(0, 0, 0, 1) 70%,
				rgba(0, 0, 0, 0) 100%);

			mask-image: $mask;
			-webkit-mask-image: $mask;

			width: 100%;
			overflow: hidden;
		
			
			p {
				font-size: .75rem;
				line-height: 1.5;
				margin: 0;
				height: 1rem;
				white-space: nowrap;
			}
		}

		.stats {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			position: relative;
			z-index: 1;

			[data-stat] {
				font-size: .5rem;
				padding: .125rem .25rem;
				display: flex;
				flex-direction: column;
				align-items: center;

				background: rgba(0,0,0,0.25);
				border-radius: 0.125rem;
				margin: 0 .25rem 0 0;

				label,
				span {
					margin: 0;
					padding: 0;
				}
				span {
					font-weight: 600;
				}
			}
		}

	}
}

.rootOld {
	max-width: 640px;
	width: 100%;
	margin: auto;
	border-radius: .5rem !important;
	position: relative;

	margin-bottom: .5rem;

	box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 
				0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;

	.content {
		padding: 1.25rem;
	}

	.media {
		&::after {
			content: ' ';
			display: block;

			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			background: var(--cover-color);
			opacity: 0.4;
		}
	}

	.textWrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// min-height: 300px;
		color: white;
		text-align: center;

		text-shadow: 0 0 .25rem black,
					 0 0 30px black;

		h1 {
			font-family: $serif-font;
			font-weight: 600;
			font-size: 1.75rem;
		}

		p {
			font-size: .8rem;
			line-height: 1.1;
		}
	}


	.cta {
		display: block;
		text-align: center;
		color: white;
		letter-spacing: 3px;
		font-weight: 200;
		font-size: .625rem;
	}

	.stats {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		position: relative;
		z-index: 1;

		color: white;
		text-shadow:  0 0 .25rem black,
		// 0 0 10px black,
		0 0 30px black;

		[data-stat] {
			font-size: .5rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			label,
			span {
				margin: 0;
				padding: 0;
			}
			span {
				font-weight: 600;
			}
		}
	}
}


body:global(.native) {
	.root {
		.name {
			height: 1.5rem;
		}

		.ref {
			font-size: .5rem;
			line-height: 1.25;
		}
	}
}