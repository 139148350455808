@import '../styles/layoutCommon.scss';

// .verseInputResult {
// 	line-height: 1.1;
// 	font-size: .75rem;
	
// 	svg {
// 		margin-right: .25rem;
// 	}
// }

$pulse-color: lighten(transparentize($highlight-color, .5), 25%);

$cover-height: 10rem;


.root {
	$foreground-color: rgba(0,0,0,0.9);
	color: $foreground-color;

	max-width: 560px;
	width: 100%;

	// This calc fixes an iOS bug where the view doesn't calc right
	min-height: calc(100vh + 1px);
	
	margin: 0 auto;

	

	// @include transition-all(.125s);
	@include noselect();

	.wrap {
		display: flex;
		flex-direction: column;
		align-items: center;

		overflow: hidden; // hide color background on mobile
		width: 100%;

		padding: 0.25rem 1rem 6rem;

		@media (min-width: 640px) {
			padding: .5rem 1rem 1rem 1rem;
		}

		h1, h3 {
			text-align: center;
			font-size: 1.5rem;
		}

		h1 {
			margin: 1rem 0;
		}

		h3 { 
			font-size: 1.1rem;
			opacity: 0.8;

		}

		:global(.MuiSelect-icon) {
			top: calc(50% - 18px);
			right: -6px;
		}

		.intro {
			font-size: .85rem;
			line-height: 1;
		}

		

		.addVerseFab {
			position: fixed;
			right:  0.5rem;
			bottom: 4rem;

			background: $highlight-color;
			color: white;

			z-index: 99;

			width: 2.5rem;
			height: 2.5rem;
		
		}

		.EditActionsFab {
			:global(.MuiBackdrop-root) {
				z-index: 1000;
			}

			.fab {
				position: fixed;
				right:  0.5rem;
				bottom: 4rem;

			}

			:global(.MuiFab-root) {
				background: $highlight-color;
				color: white;

				width: 2.5rem;
				height: 2.5rem;
			}

			:global(.MuiSpeedDialAction-tooltipPlacementLeft) 
				:global(.MuiSpeedDialAction-staticTooltipLabel) {
				width: auto;
				min-width: 8rem;
				text-align: right;

				background: rgba(0,0,0,0.625);
				color: white;
			}
		}

		// .refSelect {
		// 	display: flex;
		// 	flex-direction: row;
		// 	justify-content: center;
		// 	align-items: flex-end;

		// 	.sepAdorn {
		// 		padding: .2rem .25rem;
		// 	}

		// 	.book {
		// 		flex: 1;
		// 		width: 100% !important;
		// 		min-width: 7rem;

		// 		input {
		// 			text-align: left;
		// 		}
		// 	}

		// 	.chap,
		// 	.verse {
		// 		width: 2rem;
		// 	}
		// }

		@media (min-width: 640px) {
			.versionSelect {
				margin-top: .5rem;
			}
		}


		.versePreviews {
			padding-bottom: 4rem;
			width: 100%;

			h3 {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				text-align: left;

				margin: 1.75rem 0 .5rem;

				// color: rgba(0,0,0,0.75);
				color: rgba(255,255,255,0.8);

				span {
					line-height: 1;
				}

				.versionSelect {
					margin-left: .5rem;
				}
			}
		}

		.photoAttrib {
			margin-top: 1.25rem;
			font-size: .55rem;
			opacity: 0.65;
			color: white;

			text-shadow:  0 0 .25rem black,
				0 0 30px black;

			
			a {
				color: white;
				font-weight: 600;
				text-decoration: underline;
				text-decoration-skip-ink: auto;
			}
		}
	}
}

.EditPackDialog {
	:global(.MuiFormControl-root) {
		width: 100%;
		margin-top: .75rem;

		&:first-child {
			margin-top: -.5rem;
		}
	}
	.actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	:global(.MuiFormHelperText-root),
	:global(.MuiTypography-root):global(.MuiFormControlLabel-label) {
		line-height: 1.1;
	}

	.coverPhotoEditControl {
		display: flex;
		flex-direction: column;

		.wrap {
			display: flex;
			flex-direction: row;
			padding-top: 1rem;

			.coverPhotoPreview {
				background-color: #ccc;
				border-radius: .25rem;
				width: 4rem;
				height: 4rem;

				background-size: cover;
				
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

			}

			.icons {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				margin-left: .125rem;

				button {
					font-size: .625rem;
					line-height: 1;
					text-transform: none;
					padding: .25rem .25rem;

					justify-content: flex-start;

					:global(.MuiButton-label) {
						text-align: left;
						svg {
							margin-right: .5rem;
							transform: scale(.8);
						}	
					}
				}

				:global(.MuiCircularProgress-root) {
					margin-left: .625rem;
				}
			}

		}

		.photoAttrib {
			margin-top: .25rem;
			font-size: .55rem;
			opacity: 0.65;
		}
	}

}


// .titleCard {
// 	position: relative;
// 	padding-bottom: .5rem;
// 	overflow: visible !important;
// 	width: 100%;
// 	max-width: 640px;

// 	:global(.MuiIconButton-root) {
// 		position: absolute;
// 		right: 0;
// 		top: 0;
// 		z-index: 1;
// 	}


// 	.backBtn {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		z-index: 1;
// 		color: white;
		
// 		filter:drop-shadow(0px 0px 3px #000);
// 	}

// 	h2 {
// 		text-align: center;
// 	}

// 	:global(.MuiCardActionArea-root) {
// 		margin-bottom: -.125rem;
// 		p {
// 			line-height: 1.1;
// 			text-align: justify;
// 		}
// 	}

// 	:global(.MuiCardActions-root) {
// 		padding: 0 .75rem .5rem;
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: center;// space-between;
// 	}

// 	.customFabPositioner {
// 		position: absolute;
// 		bottom: -1.2rem;
// 		left: 50%;
// 		transform: translate(-50%, 0%);
// 		z-index: 999;
		
// 		.startButton {
// 			background: $highlight-color;
// 			color: white;

// 			transform-origin: center center;

// 			&:global(.--big) {
// 				margin-bottom: .25rem;
// 			}
// 		}
// 	}

// }

.fullBleedTitleCard,
.containedTitleCard {

	position: relative;
	// padding: 4.5rem 0 3.75rem;
	padding: 4.5rem 0 .25rem;

	width: 100%;



	.progressCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(white, 20%);
		}
	}

	.badgeCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(#5b822b, 20%);
		}
	}

	.letter {
		$width: 12rem;

		position: absolute;
		z-index: 10;

		// top: 50%;
		left: 50%;
		top: $cover-height * .525;
		transform: translate(-50%, -50%);
		// color: var(--pack-text, white);
		color: white;
		// border: 1px solid white;
		border-radius: 50%;
		$size: ($width * 9/16) * .5;
		font-size: $size * .75;
		line-height: 1;
		font-weight: 800;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// width: $size + 1rem;
		// height: $size + 1rem;

		// Yes, ugg, pixels.
		// This is just because <CircleProgress>
		// requires pixels for SVG work
		width: 132px;
		height: 132px;

		.text {
			position: relative;
		}
	}

	
	.openHeaderInfo {
		position: absolute;
		z-index: 3;
		right: -.75rem;
		top: $cover-height - 3rem;
		
		transition: opacity $default-transition-time $my-cubic-transition;
	}

	.coverPhoto {
		position: absolute;
		top: -.5rem;
		left: -1.5rem;
		right: -1.5rem;
		// bottom: 33vh;
		bottom: auto;
		height: $cover-height;

		transition: height $default-transition-time $my-cubic-transition;

		// z-index: -1;
		z-index: 2; // above more info menu button in verses

		background-size: cover;
		// background-image set in JSX
		background-position: center center;

		background-image: none !important;
		
		background-color: var(--pack-color);


		box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.5), 
			0px 2px 10px 0px rgba(0,0,0,0.4),
			0px 3px 10px 0px rgba(0,0,0,0.2), 
			0px 5px 18px 0px rgba(0,0,0,0.15);


		// $mask: linear-gradient(to bottom,
		// 	rgba(0, 0, 0, 1) 0%,
		// 	rgba(0, 0, 0, 1) 70%,
		// 	rgba(0, 0, 0, 0) 100%);


		// mask-image: $mask;
		// -webkit-mask-image: $mask;

		// &::after {
		// 	content: ' ';
		// 	display: block;

		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	bottom: 0;
		// 	right: 0;

		// 	background: var(--cover-color);
		// 	opacity: 0.425;
		// }

	}


	// .coverColor {
	// 	content: ' ';
	// 	display: block;

	// 	position: fixed;
	// 	z-index: -2;
	// 	top: 0;
	// 	left: 0;
	// 	bottom: 0;
	// 	right: 0;

	// 	background: var(--pack-color, var(--cover-color));
	// 	opacity: 0.9;
	
	// }



	.textContentWrap {
		// background: rgba(255,255,255,0.5);
		// text-shadow:  0 0 .25rem black,
		// 	// 0 0 10px black,
		// 	0 0 30px black;

		margin-top: 6rem;
		
		color: white;
		text-align: center;

		h1 {
			// font-weight: 700;
			// font-size: 2.25rem;
			// font-family: $serif-font;

			font-weight: 600;
			font-size: 1.125rem;
			margin: 0 0 .5rem 0;
		}


		p {
			font-size: .625rem;
			line-height: 1.1;
			margin: 0 0 0.5rem;
			// letter-spacing: -0.1px;

			// text-shadow:  0 0 .25rem black,
			// 	0 0 10px black,
			// 	0 0 30px black;
		
		}

		margin-bottom: .75rem;
	}

	.fabWrap {
		// position: absolute;
		// // bottom: -1.2rem;
		// bottom: 1.25rem;
		// left: 50%;
		// transform: translate(-50%, 0%);
		// z-index: 10;
		position: relative;
		z-index: 5;

		opacity: 1;
		transition: opacity $default-transition-time $my-cubic-transition;

		margin-top: 1rem;
		margin-bottom: .125rem;

		width: 100%;
		
		.fab {
			background: $highlight-color;
			color: white;

			transform-origin: center center;

			// box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;

			box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 
				0px 6px 10px 0px rgba(0,0,0,0.2), 
				0px 1px 18px 0px rgba(0,0,0,0.15);

			width: 100%;

			label {
				margin: 0 0 0 .5rem;
				padding: 0;
				text-transform: none;
			}

		}

		&[data-hasprogress=true] {
			--bar-color: white;

			.percent {
				display: none;
			}

			// margin-bottom: 0;

			.fab {
				position: relative;
				overflow: hidden;

				// &::after {
				// 	content: ' ';
				// 	display: block;
				// 	position: absolute;
				// 	bottom: 0;
				// 	left: 0;
				// 	height: 0px;
				// 	width: var(--progress-percent, 0%);
				// 	border: 2px solid var(--bar-color, red);
				// 	border-radius: 1.5px;
				// }

				// &::before {
				// 	$size: 16px;
				// 	content: ' ';
				// 	display: block;
				// 	position: absolute;
				// 	width: $size;
				// 	height: $size;
				// 	border-radius: 50%;
				// 	background: var(--bar-color, red);

				// 	bottom: -$size / 2;
				// 	// Can't use scss vars in calc() - arggg
				// 	left: calc(var(--progress-percent, 0%) - 16px/2);
				// }
			}
		}

		.restartPack {
			color: white;
			font-size: .625rem;
			width: 100%;
			// margin: .5rem 0 -.5rem;
			margin: .25rem 0 .25rem 0;
			padding: .25rem;
			border-radius: .25rem;

			label {
				margin: 0 0 0 .5rem;
				padding: 0;
			}
		}
	}

	.followFabWrap {
		// border: 5px solid white;
		border-radius: 1.5rem;
		
		// box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 
		// 	0px 6px 10px 0px rgba(0,0,0,0.2), 
		// 	0px 1px 18px 0px rgba(0,0,0,0.15);

		.followFab {
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
			// transform: scale(1) translate(-50%, 0%);
			animation: pulse_follow_fab 2s infinite;
			
			
			svg {
				margin-right: .5rem;
			}
		}
	}

	.backBtn {
		position: absolute;
		top: 0;
		left: -.75rem;
		color: white;
		filter:drop-shadow(0px 0px 3px #000);
	}

	.moreMenu {
		position: absolute;
		top: 0;
		right: -.75rem;
		color: white;
		filter:drop-shadow(0px 0px 3px #000);
	}

	.actionMenu {
		position: absolute;
		top: 0;
		right: -.75rem;
		color: white;
		filter:drop-shadow(0px 0px 3px #000);
		z-index: 3;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		.moreActions {
			position: relative;
		}
	}

	.followInfo {
		position: absolute;
		top: .125rem; // 7.6rem;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;

		padding: 12px; // match icon button

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: .75rem 3rem;

		opacity: 0.65;
		font-size: .5rem;
		width: 100%;

		color: white;
		text-shadow:  0 0 .25rem black,
			// 0 0 10px black,
			0 0 30px black;

		white-space: nowrap;

		.dot {
			margin: 0 .5rem;
			position: relative;

			&::after {
				display: block;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				position: absolute;
				border-radius: 50%;
				background: white;
				content: ' ';
				width: .125rem;
				height: .125rem;

				box-shadow:  0 0 .25rem black,
					// 0 0 10px black,
					0 0 30px black;
			}
		}
	}

	.stats {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		position: relative;
		z-index: 1;

		// background: rgba(0, 0, 0, 0.08);
		// border-radius: .5rem;

		// padding: 1rem .25rem .25rem;
		// margin-top: -1.5rem;

		color: white;
		// text-shadow:  0 0 .25rem black,
		// // 0 0 10px black,
		// 0 0 30px black;

		[data-stat] {
			// font-size: .5rem;
			// padding: 0 .25rem;
			// display: flex;
			// flex-direction: column;
			// align-items: center;

			font-size: .5rem;
			padding: .125rem .35rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			// background: rgba(0,0,0,0.25);
			// border-radius: 0.125rem;
			// border-radius: 4px; // match verses
			margin: 0 .25rem 0 0;

			// box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 
			// 		0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;

			& + [data-stat] {
				border-left: 1px solid rgba(255,255,255,0.325);
			}


			label,
			span {
				margin: 0;
				padding: 0;
				white-space: nowrap;
			}
			span {
				font-weight: 600;
			}
			label {
				opacity: 0.5;
			}
		}
	}


	&.containedTitleCard {
		max-width: 560px;
		width: 100%;

		// padding: 2.5rem 0 2.75rem;
		padding: 2.5rem 0 .5rem;

		box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 
					0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;

		:global(.MuiIconButton-root),
		:global(.MuiButton-root) {
			color: $foreground-color !important;
		}

		.openHeaderInfo {
			right: .1rem;
			top: $cover-height - 4rem;
		}
		
		.stats {
			padding: .25rem 1.5rem 0;
		}
		
		.letter {
			font-size: 2rem;
		}

		.actionMenu {
			right: -.125rem;
		}

		.coverPhoto {
			position: absolute;
			bottom: 0;
			z-index: 0;
			height: auto;

			mask-image: none;
			-webkit-mask-image: none;
		}

		.backBtn {
			left: 0;
		}

		.moreMenu {
			right: 0;
		}

		.coverColor {
			display: none;
		}

		.textContentWrap {
			padding: 0 1rem;
			position: relative;
			margin: 7rem 0 1rem 0;

			h1 {
				font-weight: 600;
				font-size: 1.1rem;
			}

			p {
				margin-bottom: 1.25rem;
			}
		}

		.fabWrap {
			// position: -1.25rem;
			width: 75%;
			margin: 1rem auto .33rem auto;

			.fab {
				// padding: .125rem 0;
				// // border-radius: 0 50% 0 0;
				// // border-radius: .125rem;
				// height: 1.5rem;
				// border-radius: calc(1.5rem / 2);
				// height: auto;
				box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.5), 
							0px 2px 10px 0px rgba(0,0,0,0.4),
							0px 3px 10px 0px rgba(0,0,0,0.2), 
							0px 5px 18px 0px rgba(0,0,0,0.15);
			}
		}

		.headerInfo {
			pointer-events: none;

			h1 {
				// font-size: 6275rem;
				display: none;
			}

			.stats {
				position: absolute;
    			top: -7.5rem;
				left: 50%;
				transform: translateX(-50%);
			}

			.circles {
				transform: translateY(-.5rem);
			}
		}
	}


	.closeHeaderInfo {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		pointer-events: none;
		transition: opacity $default-transition-time $my-cubic-transition;
		z-index: 4;
	}


	.headerInfo {
		max-width: 560px;
		width: 100%;
	
		position: absolute;
		z-index: 99;
		color: white;
		opacity: 0;
		top: 8rem;
		transform: translateY(-100vh);
		transition: opacity $default-transition-time $my-cubic-transition,
			transform $default-transition-time $my-cubic-transition;


		h1 {
			font-size: 1rem;
			margin: 0;

			position: absolute;
			// top: 0.5rem;
			top: -7.5rem;
			left: 50%;
			transform: translateX(-50%);
			width: calc(100% - 4rem);
			text-align: center;

			text-overflow: ellipsis;
			white-space: nowrap;


			$mask: linear-gradient(to right,
				rgba(0, 0, 0, 1) 0%,
				// rgba(0, 0, 0, 1) 80%,
				rgba(0, 0, 0, 1) 90%,
				rgba(0, 0, 0, 0) 100%);


			mask-image: $mask;
			-webkit-mask-image: $mask;
		}

		.circles {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 1.5rem;

			.block {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				position: relative;

				padding: 48px .125rem 0;

				:global(.CircleProgress-root) {
					top: 16px;
					left: 50%;
				}

				.info {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;

					label {
						margin: 0;
						font-size: 0.625rem;
						line-height: 1.1;
						font-weight: 600;

						text-align: center;

						&.hint {
							font-weight: normal;
							opacity: 0.62;
							font-size: 0.5rem;
							margin-top: .125rem;
						}
					}

					.data {
						font-weight: 600;
						font-size: 0.87rem;
						margin: .25rem 0 -.25rem;
					}

					.dataSubtext {
						opacity: 0.7;
						font-size: 0.5rem;
					}
				}

				&.badgeLevel {
					.data {
						.value {
							color: lighten(#5b822b, 20%);
						}
					}
				}

				&.progressPercent {
					.data {
						.value {
							color: white;
						}
					}
				}
			}


		}
	}

	.followInfo,
	.moreMenu,
	.backBtn,
	.actionMenu,
	.textContentWrap {
		z-index: 3;
		transition: transform $default-transition-time $my-cubic-transition,
			opacity $default-transition-time $my-cubic-transition;
		// transform: translateY(0);
	}

	.headerInfoOpen {
		.followInfo,
		.moreMenu,
		.backBtn, 
		.actionMenu {
			transform: translateY(-10rem);
		}
		
		.headerInfo {
			opacity: 1;
			transform: translateY(0);
		}

		.coverPhoto {
			height: 18.25rem;
		}
		
		.fabWrap,
		.textContentWrap {
			opacity: 0;
			pointer-events: none;
		}

		.openHeaderInfo {
			opacity: 0;
			pointer-events: none;
		}

		.closeHeaderInfo {
			opacity: 1;
			pointer-events: all;
		}
	}
}


@keyframes pulse_follow_fab {
	0% {
		transform: scale(0.95);// translate(-50%, 0%);
		box-shadow: 0 0 0 0 transparentize($pulse-color, .9);
	}

	70% {
		transform: scale(1);// translate(-50%, 0%);
		box-shadow: 0 0 0 10px transparentize($pulse-color, .2);
	}

	100% {
		transform: scale(0.95);// translate(-50%, 0%);
		box-shadow: 0 0 0 0 transparentize($pulse-color, .5);
	}
}

body:global(.native) {
	.headerInfoOpen {
		.coverPhoto {
			height: 20.5rem;
		}
	}
}

body:global(.notched) {
	.fullBleedTitleCard {
		.closeHeaderInfo,
		.backBtn,
		.followInfo,
		.moreMenu,
		.actionMenu {
			top: $notch-size;
		}

		// .followInfo {
		// 	top: 7.6rem + $notch-size;
		// }

		.headerInfo {
			top: $notch-size + 8rem;
		}

		.coverPhoto {
			height: $cover-height + $notch-size;
		}

		padding-top: $notch-size + 4.5rem;
	}

	.fullBleedTitleCard,
	.containedTitleCard {
		.letter {
			top: $cover-height * .525 + $notch-size;
		}
	}

	.openHeaderInfo {
		top: $cover-height - 3rem + $notch-size;
	}

	.headerInfoOpen {
		.coverPhoto {
			height: 18.25rem + $notch-size;
		}
	}

	.containedTitleCard {
		margin-top: $notch-size;
	}

	.root {
		.addVerseFab { 
			bottom: 3.625rem + $notch-size;
		}
	}
}

