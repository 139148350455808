@import '../styles/layoutCommon.scss';

.root {
	color: $foreground-color;

	max-width: 560px;
	width: 100%;
	// The 1rem is a bugfix for WKWebView in iOS
	min-height: calc(100vh + 1rem);

	margin: 0 auto;

	padding-bottom: 4rem;

	@include transition-all(.125s);
	@include noselect();


	// Safari chokes on this
	// display: flex;
	// flex-direction: column;
	// align-items: center;

	.appBar {
		// $foreground-color: rgba(0,0,0,0.9);
		color: $foreground-color;

		// background: darken(rgb(255,250,255), 3%);
		background: black;

		.menuButton svg {
			fill: $foreground-color;
		}

		.title {
			font-size: 1rem;
			flex-grow: 1;
			text-align: center;
			padding-right: 2rem;
		}
	}

	h1 {
		text-align: center;
		display: none;
	}

	.banner {
		margin-top: 1.5rem;
	}

	.btnLogout {
		background: #7951A9;
		color: white;
		padding: 0.25rem .75rem;

		margin: 1.5rem 1rem .5rem;
	}

	.connectFb {
		margin: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		button {
			width: 100%;
			max-width: calc(100% - 2rem);

			background: #7951A9;
			color: white;
			padding: 0.25rem .75rem;

			margin: 1.5rem 1rem .5rem;

			@include fb-btn-overrides();

		}

		p {
			margin: 0;
			font-size: .625rem;
			opacity: 0.8;
			line-height: 1.1;
			text-align: center;

			width: 100%;

		}
	}

	svg {
		color: $foreground-color;
	}

	:global(.MuiButtonBase-root) {
		svg {
			color: transparentize($foreground-color, 0.33);
		}
	}

	:global(.MuiFab-label) {
		svg {
			// color: rgba(0,0,0,0.77);
			color: #90335a;
		}
	}

	// :global(.MuiListItem-root) {
	.myListItem {
		// default for mui list items is flex-start
		justify-content: space-between;
		display: flex; // for use with subheader
		flex-direction: row;  // for use with subheader
		align-items: center;  // for use with subheader
	
		color: $foreground-color;

		min-height: 2rem;
	}

	.settingsFields {
		width: 100%;

		display: flex;
		flex-direction: column;
		text-align: left;
		// padding: .5rem;

		label {
			margin-top: .5rem;
			margin-bottom: 0rem;
		}

		$font-size: .825rem;
		:global(.MuiTypography-body2) {
			font-size: $font-size;
			opacity: 0.8;
		}

		:global(.MuiTypography-body1),
		:global(.MuiInputBase-input) {
			font-size: $font-size;
			font-weight: 600;
		}

		.versionSelect {
			:global(.MuiSelect-icon) {
				transform: translate(.5rem, -.4rem);
			}
		}

		.contacts {
			// padding: 0 16px; // match material ui for settings panel padding
			label {
				margin-top: -1rem;
				padding: 0 16px; // match material ui for settings panel padding
				font-size: .875rem; // mui body2
			}
		}

		.table {
			width: 100%;
			margin: 0.125rem 0 0;
			border: 0.2rem solid white;
			background: rgba(0,0,0,0.33);

			// MUI compat
			width: calc(100% + .4rem); // hide borders on both sides of screen
			margin-left: -.2rem; // compensate for extra width
			
			td, th {
				color: white;
				// padding: 0.5rem;
				padding: 4px 16px; // mui compat
				// font-size: 0.75rem;
				border-color: rgba(255,255,255,0.77);
			}

			th {
				background: rgba(0,0,0,0.33);
			}

			//
			// Don't need these 0 paddings unless we remove the border
			//
			// td:first-child,
			// th:first-child {
			// 	padding-left: 0;
			// }

			// td:last-child,
			// th:last-child {
			// 	padding-right: 0;
			// }
			
			td {
				@include force-word-break();
			}
		}

		.addNewContact {
			margin-top: 0;
			border-top: 0;

			// MUI compat
			width: calc(100% + .4rem); // hide borders on both sides of screen
			margin-left: -.2rem; // compensate for extra width
			padding: 16px; // mui compat
		}
	}

	.welcomeFinalPage {
		margin: 1.7rem 0 1.5rem;

		p {
			margin: 0;
			text-align: center;
		}
	}

	.settingsList {
		margin-top: 2.5rem;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.dndList {
		margin-top: 0;
		padding-top: 0;
	}

	.versionInfo {
		text-align: center;
		padding: 1rem 0;
	}

	.addActivityBtn {
		margin-right: .8rem;
	}

	.mySortableList {
		margin-top: 0;
		padding-top: 0;
	}

	.mySortableListItem {
		padding-top: 0;
		padding-bottom: 0;
	}

	.actHeader {
		margin-top: .5rem;
		font-weight: bold;
	}
}

.dialogFieldSet {
	display: flex;
	// flex-wrap: wrap;
	flex-direction: column;

	.textField {
		flex-basis: 200;
	}

	:global(.MuiInputLabel-shrink) {
		transform: translate(0, 4px) scale(0.75);
	}
}

.customDialogActions {
	// Yes, must use !important to override MUI
	justify-content: space-between !important;
}



body:global(.notched) {
	.appBar, .root {
		padding-top: $notch-size;
	}
}