@import '../styles/layoutCommon.scss';

.root {
	// The 1rem is a bugfix for WKWebView in iOS
	min-height: calc(100vh + 1rem);

	$foreground-color: rgba(255,255,255,0.9);
	color: $foreground-color;


	.searchBar {
		// background: darken(rgb(255,250,255), 1%);		
		// color: rgba(0, 0, 0, 0.54);
		background: rgba(0,0,0,0.9);
		color: $foreground-color;

		box-shadow: 
			0 0 10px rgba(0,0,0,0.8),
			0 0 30px rgba(0,0,0,0.4);

		padding: .25rem 0;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		
		// :global(.MuiToolbar-root) {
		// 	max-width: 640px;
		// }

		.searchWrap {
			max-width: 640px;
			width: 100%;		

			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			align-items: center;

			padding-right: .5rem;
			

			.searchButton {
				margin-left: .25rem;
				svg {
					fill: $foreground-color;
					// margin: .5rem;
					opacity: 0.8;
				}
			}

			.search {
				flex: 1;

				input {
					color: $foreground-color;

					&::placeholder {
						color: transparentize($foreground-color, .25);
					}
				}
			}
		}
	}

	:global(.MuiInputLabel-root) {
		margin: .75rem 0 -.5rem .35rem;
		font-size: .75rem;

		& + :global(.MuiFormHelperText-root) {
			font-size: .5rem;
			margin: 0 0 -.1rem .35rem;
			line-height: 1;
		}
	}

	.contentWrap {
		$foreground-color: rgba(0,0,0,0.9);
		color: $foreground-color;

		max-width: 640px;
		width: 100%;

		margin: 0 auto;
		padding-top: 2.5rem;
		padding-bottom: 4rem;

		// @include transition-all(.125s);
		@include noselect();

		.searchResults {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
			flex-wrap: wrap;

			padding: .5rem 0 0 .125rem;

			.item {
				padding-bottom: .5rem;
			}

			.badgeOverride {
				background: rgba(0, 0, 0, 0.825);
				color: #f0f0f0;
				box-shadow: 0 0 3px rgba(0,0,0,.3);
			}


		}
	}

	
}



body:global(.notched) {
	.searchBar {
		padding-top: $notch-size + .25rem;
	}

	.contentWrap {
		padding-top: $notch-size + 3rem;
	}
}