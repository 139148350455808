@import '../styles/layoutCommon.scss';

.root {
	// $foreground-color: rgba(0,0,0,0.9);

	// color: white;

	color: $foreground-color;
	min-height: 100vh;

	max-width: 560px;
	width: 100%;

	margin: 0 auto;

	padding: .5rem;

	.versionSelect {
		:global(.MuiSelect-icon) {
			transform: translate(.5rem, -.4rem);
		}
	}

	@include noselect();


	.bannerWrap {
		position: fixed;
		top: 0rem;
		left: 50%;
		transform: translateX(-50%);


		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.btnSkip {
		position: fixed;
		bottom: .75rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
		padding: 0.5rem 2rem;
	}

	.connectFb {
		margin: .75rem 0 .75rem;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		button {
			width: 100%;
			// max-width: calc(100% - 2rem);

			background: #7951A9;
			color: white;
			padding: 0.25rem .75rem;

			text-align: left;
			text-transform: none;

			position: relative;
			align-items: center;
			justify-content: flex-start;

			svg {
				margin-right: .6rem;
			}

		}

		button.fb {
			// margin: 1.5rem 1rem .5rem;
			margin: 0 0 .25rem;

			@include fb-btn-overrides();
		}

		button.apple {
			margin: 0 0 .25rem 0;

			background: white;
			color: black !important;
			
			svg {
				fill: black !important;
				transform: translateY(-.1rem);
			}
		}

		p {
			margin: .75rem 0 0 0;

			font-size: .625rem;
			opacity: 0.8;
			line-height: 1.1;
			text-align: center;

			width: 100%;

			.noThanks {
				display: block;
				font-weight: 600;
				color: white;
				cursor: pointer;
			}
		}
	}

	.finalPage {
		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) 1rem,
			rgba(0, 0, 0, 1) 95%,
			rgba(0, 0, 0, 0) 100%);

		mask-image: $mask;
		-webkit-mask-image: $mask;	

		position: fixed;
		top: 6.5rem;
		left: 50%;
		bottom: 0;
		width: calc(100% - 0.5rem);
		max-width: 600px;
		transform: translateX(-50%);
		z-index: 10;
		padding: 0.5rem 1rem;

		overflow: auto;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		h4, h5 {
			margin: .75rem 0 .5rem;
			font-size: .85rem;
			text-align: center;
		}

		h4 {
			margin: 1.25rem 0 .25rem;
		}

		:global(.MuiInputLabel-shrink) {
			transform: translate(0, 4px) scale(0.75);
		}

		.yourName {
			margin-bottom: 1rem;
		}

		.preferredVersion {
			margin-top: 1.5rem;

			:global(.MuiInputLabel-shrink) {
				transform: translate(0, -1.5rem) scale(0.75);
			}	
		}

		.goalVersesDay {
			margin-top: 1.25rem;

			:global(.MuiInputLabel-shrink) {
				transform: translate(0, -.25rem) scale(0.75);
			}
			
			:global(.MuiInputAdornment-positionEnd) {
				margin: 0 0 .175rem .5rem;
				cursor: default;
			}

			input[type=number] {
				text-align: right;
			}

			:global(.MuiFormHelperText-root) {
				line-height: 1;
				font-size: .625rem;
			}

			// :global(.MuiInputBase-root) {
			// 	width: 50%;
			// 	max-width: 10rem;
			// 	margin: 0 auto;
			// }
		}


	}

	:global(.SimpleBanner-root) {
		// margin: -4rem auto 0;
		
	}

	.pageTitle {
		font-size: 1.2rem;
	}

	.myCard {
		text-align: center;

		max-width: 600px;
		width: calc(100% - 2rem);


		svg {
			font-size: 3rem;
			margin: 1.5rem 0 .75rem;
		}

		h1 {
			font-size: 1rem;
			font-weight: bold;
			margin: 0;
		}

		p {
			margin: .75rem 1rem;
			max-width: calc(100% - 2rem);
			
		}

	}

	:global(.awssld__bullets) {
		bottom: 3.5rem;
		background-color: transparent;
	}

	:global(.awssld__controls) {
		button {
			&, &:active, &:hover, &:focus {
				outline: none;
			}
		}
	}

	.btnGo {
		background: $highlight-color;
		color: white;

		&:hover, &:active, &:focus {
			background: lighten($highlight-color, 5%);
		}

		border-radius: 1.5rem;

		margin: 1rem 0 1rem;

		padding: 0.25rem 1.75rem;
	}
}