@import '../styles/layoutCommon.scss';

.root {
	position: relative;
	z-index: 0;
	// $foreground-color: rgba(0,0,0,0.9);
	$foreground-color: rgba(255,255,255,0.9);
	color: $foreground-color;

	max-width: 640px;
	width: 100%;
	// The 1rem is a bugfix for WKWebView in iOS
	min-height: calc(100vh + 1rem);

	margin: 0 auto;

	// padding-bottom: 4rem;
	padding-bottom: 0rem;
	margin-bottom: 9rem;

	// display: flex;
	// flex-direction: column;
	// align-items: center;

	.settingsLink {
		opacity: 0.7;
		position: absolute;

		// top: 50%;
		// right: 0;
		// top: .75rem;
		// right: -.75rem;
		top: 0;
		right: 0;
		// transform: translate(0, -50%);

		color: $foreground-color;

		// &.alertPresent {
		// 	top: 3.625rem;
		// }

		svg {
			transform: scale(.75);
		}
	}



	& > h1 {

		text-align: center;

		
		font-weight: 600;
		font-size: 1.125rem;
		margin: 2rem 0 .5rem 0;

	}


	.progressCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(white, 20%);
		}
	}

	.badgeCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(#5b822b, 20%);
		}
	}

	.mainPoints {
		// // position: absolute;
		// // top: -.125rem;
		// left: 50%;
		// transform: translateX(-50%);

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		// position: relative;
		
		// label {
		// 	margin: 0;
		// 	font-size: 0.625rem;
		// 	line-height: 1.1;
		// 	font-weight: 600;

		// 	text-align: center;

		// 	&.hint {
		// 		font-weight: normal;
		// 		opacity: 0.62;
		// 		font-size: 0.475rem;
		// 		margin-top: .125rem;
		// 	}
		// }

		margin: .25rem 0;

		.data {
			font-weight: 600;
			font-size: 1.25rem;
			margin: 0 0 -.25rem;

			.value {
				color: $highlight-color;
			}
		}

		.dataSubtext {
			// opacity: 0.7;
			// font-size: 0.5rem;
			// text-align: center;
			// margin-top: .5rem;

			font-size: 0.625rem;
			line-height: 1.1;
			font-weight: 600;
		}
	}

	.letter {
		$width: 12rem;

		// position: absolute;
		// z-index: 10;
		// top: 50%;
		// left: 50%;
		// top: 7.25rem;
		// transform: translate(-50%, -50%);

		// color: var(--pack-text, white);
		color: white;
		// border: 1px solid white;
		border-radius: 50%;
		$size: ($width * 9/16) * .5;
		font-size: $size * .75;
		line-height: 132px; // yuck px...svg...
		font-weight: 800;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// width: $size + 1rem;
		// height: $size + 1rem;

		// Yes, ugg, pixels.
		// This is just because <CircleProgress>
		// requires pixels for SVG work
		width: 132px;
		height: 132px;

		margin: .5rem auto 0;

		.text {
			position: relative;
		}
	}



	.headerInfo {
		max-width: 560px;
		width: 100%;
	
		// position: absolute;
		// z-index: 99;
		color: white;
		opacity: 1;
		margin: -1.825rem auto 0;
		// transform: translateY(-100vh);
		transition: opacity $default-transition-time $my-cubic-transition,
			transform $default-transition-time $my-cubic-transition;


		.circles {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			// margin-bottom: 1.5rem;

			position: relative;

			.block {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				position: relative;

				padding: 48px .125rem 0;

				:global(.CircleProgress-root) {
					top: 16px;
					left: 50%;
				}

				.info {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;

					label {
						margin: 0;
						font-size: 0.625rem;
						line-height: 1.1;
						font-weight: 600;

						text-align: center;

						&.hint {
							font-weight: normal;
							opacity: 0.62;
							font-size: 0.475rem;
							margin-top: .125rem;
						}
					}

					.data {
						font-weight: 600;
						font-size: 0.87rem;
						margin: .25rem 0 -.25rem;
					}

					.dataSubtext {
						opacity: 0.7;
						font-size: 0.5rem;
						text-align: center;
					}
				}

				&.badgeLevel {
					.data {
						.value {
							color: lighten(#5b822b, 20%);
						}
					}
				}

				&.progressPercent {
					.data {
						.value {
							color: white;
						}
					}
				}
			}
		}
	}

	.filterBox {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		margin: 1rem 0 0; //.125rem;

		:global(.MuiButton-containedPrimary) {
			background: $highlight-color;
		}

		:global(.MuiButton-root) {
			text-transform: none;
			border-color: $highlight-color;
		}

		.todayBtn {
			opacity: 1;
		}
		
	}

	.dayChart {
		// So simple, it's stupid
		// Had to follow this: https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;

		flex-direction: row;
		// justify-content: center;
		align-items: flex-start;

		margin: 0;
		width: 100%;
		
		padding: 0 .125rem .125rem .125rem;

		-webkit-overflow-scrolling: touch;
		// &::-webkit-scrollbar {
		// 	display: none;
		// }

		.day {
			// The secret sauce and magic, Demo here https://codepen.io/colinlord/pen/oZNoOO
			flex: 0 0 auto;
		}

		// &.overflows {
			justify-content: flex-start;

			.day {
				&:last-of-type {
					padding-right: 1rem;
				}
			}
		// }
	}

	$day-height: 4rem;
		
	.dayChart {
		margin-top: .75rem;

		$day-width: .5rem;
		$col-width: $day-width * .825;
		$label-height: 1rem;
		.day {
			font-size: .5rem;

			height: $day-height;
			width: $day-width;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;


			.score {
				
				width: $col-width;
				background: lighten(#5b822b, 20%);
				height: calc(var(--time-percent) - #{$label-height * 1.5});

				border-top-right-radius: $col-width/2;
				border-top-left-radius: $col-width/2;

				position: relative;
				&::after {
					content: ' ';
					position: absolute;
					display: block;
					// background: lighten(white, 20%);
					background: transparentize(white, .125);
					bottom: 0;
					width: 100%;
					height: var(--verses-percent);

					border-top-right-radius: $col-width/2;
					border-top-left-radius: $col-width/2;
				}
			}

			label {
				// display: none;
				opacity: 0;
				height: $label-height;
				max-height: $label-height;
				text-align: center;
			}

			&.showLabel {
				label {
					// display: block;
					opacity: 1;
				}
			}


			opacity: 0.825;

			$br: $day-width/2;
			border-radius: $br;
			cursor: pointer;

			&.today {
				// background: transparentize($highlight-color, .5);
				background: rgba(255,255,255,0.2);
			}

			&.afterToday {
				opacity: 0.35;
			}

			&.selected {
				opacity: 1;
				background: transparentize($highlight-color, .5);
				// background: rgba(255,255,255,0.2);
				label {
					font-weight: bold;
					// opacity: 1;
				}

				border-radius: 0;
				// border-right: 2px solid black;
				// margin: 0 1px;
				&.selectedFirst {
					border-top-left-radius: $br;
					border-bottom-left-radius: $br;
				}
				&.selectedLast {
					// border-right: 0;
					// margin-right: 0;
					border-top-right-radius: $br;
					border-bottom-right-radius: $br;
				}
			}
		}
	}

	.dayChart {
		opacity: 0;
		transform: translateY(-#{$day-height}) scale(.5);
		transition: transform $default-transition-time $my-cubic-transition,
			opacity $default-transition-time $my-cubic-transition;

		pointer-events: none;
		&.open {
			pointer-events: all;
			opacity: 1;
			transform: translateY(0) scale(1);
		}
	}


	.verses {
		padding: 0rem 0 4rem;
		margin: 0 auto;
		width: 100%;
		max-width: 560px;

		transition: transform $default-transition-time $my-cubic-transition;

		transform: translateY(-#{$day-height});

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
			
		&.chartOpen {
			transform: translateY(0);
			// padding-bottom: 4rem + $day-height;
		}

		.emptyCta {
			opacity: 0.7;
			font-size: 0.75rem;
			line-height: 1;
			text-align: center;
			margin: .75rem 1rem 1rem;

			align-self: center;

			.ctaButtons {
				margin-top: 1.45rem;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				button {
					border-radius: 1rem;

					font-size: .75rem;

					padding-left: 1rem;
					padding-right: 1rem;

					text-transform: none;

					background: $highlight-color;

					svg {
						margin-right: .25rem;
					}

					b {
						display: inline-block;
						margin-left: .25rem;
					}
				}

				button:first-of-type {
					// border-top-right-radius: 0;
					// border-bottom-right-radius: 0;
					// padding-right: .25rem;
				}

				button:last-of-type {
					// padding-left: .25rem;
					// border-top-left-radius: 0;
					// border-bottom-left-radius: 0;
				}
			}
		}

		.points {
			align-self: center;

			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			// position: relative;
			
			// label {
			// 	margin: 0;
			// 	font-size: 0.625rem;
			// 	line-height: 1.1;
			// 	font-weight: 600;

			// 	text-align: center;

			// 	&.hint {
			// 		font-weight: normal;
			// 		opacity: 0.62;
			// 		font-size: 0.475rem;
			// 		margin-top: .125rem;
			// 	}
			// }

			margin: 0 0 .5rem 0;

			.data {
				font-weight: 600;
				// font-size: 1.25rem;
				font-size: 0.87rem;
				margin: 0 0 -.25rem;

				.value {
					color: $highlight-color;
				}
			}

			.dataSubtext {
				opacity: 0.7;
				font-size: 0.5rem;
				text-align: center;
				// margin-top: .5rem;

				// font-size: 0.625rem;
				// line-height: 1.1;
				// font-weight: 600;
			}
		}

		.versesList {
			width: 100%;

			padding: 0;
			

			:global(.MuiListItem-gutters) {
				padding-left: .125rem;
			}

			:global(.MuiListItemAvatar-root) {
				position: relative;
			}
		}	
	}
}



body:global(.notched) {
	.root {
		padding-top: $notch-size;

		.settingsLink {
			top: $notch-size;
		}

		.letter {
			// top: $notch-size + 7.25rem;
			margin: 1.35rem auto 0;
		}
	}
}

// body:global(.native) {
// 	.root {
// 		:global(.MuiInputLabel-root) {
// 			margin-top: 1.25rem;
// 		}
// 	}
// }