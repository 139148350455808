@import '../pages/styles/layoutCommon.scss';

.AddPackDialog {
	.loadingSpinner {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 4rem;
	}

	button {
		text-transform: none;
	}

	.actions {
		justify-content: space-between;
		width: 100%;
	}
}