@import '../pages/styles/layoutCommon.scss';
.root {
	// @include transition-all(.3s);
	@include noselect();
	
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// position: relative;

	// padding-bottom: 2rem;

	// @include customIgOverlayDark();
	// @include customIgOverlayLight();

	.backLink,
	.editLink {
		color: white;
		// position: fixed;
		// left: 1rem;
		// top: 1rem;

		svg {
			transform: scale(2);
		}
	}

	.editLink {
		left: auto;
		// right: 1rem;
		svg {
			transform: scale(1.125);
		}
	}

	.trainingPopup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2999;

		padding: .5rem;

		background: rgba(0,0,0,0.5);
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include transition-all(.3s);
		opacity: 0;
		pointer-events: none;

		&.visible {
			opacity: 1;
			pointer-events: all;
		}

		.inner {
			box-shadow: 0 0 30px rgba(0,0,0,0.2);
			background:  rgba(255,250,255, 1);
			border-radius: .25rem;
			padding: 1rem;

			max-width: 560px;
			width: 100%;
			// height: 90%;
			overflow: auto;

			line-height: 1.2;
			font-size: 1.1rem;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p:last-of-type {
				margin-bottom: 0;
			}

			.trainingAnswerDemo {
				background: rgba(255,255,0,0.33);
				border-radius: 0.25rem;
				padding: 0.125rem  0.25rem;
				margin: -0.125rem -0.25rem;
				position: relative;

				&.trainingBad {
					background: rgba(255,0,0,0.33);
					// line-height: 1.85;
				}

				&.trainingGood {
					background: rgba(0,255,0,0.33);
				}
			}
		}
	}

	.packName {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		// background-color: #7951A9;
		// background-color: rgba(0,0,0,0.25);
		// color: rgba(0,0,0,0.9);

		// background: darken(rgb(255,250,255), 1%);
		// color: rgba(0, 0, 0, 0.54);
		color: $foreground-color;
		background: black;
		box-shadow: 0 0 30px rgba(0,0,0,0.2);

		padding: .5rem 0;
		z-index: 100;
		// opacity: 0.7;
		// margin-top: 5rem;
		// margin-bottom: 1rem;

		// font-size: 1rem;
		// margin: .5rem .125rem;

		.wrap {
			position: relative;

			.stopBtn {
				position: absolute;
				left: .625rem;
				top: -.125rem;
			}

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			:global(.MuiIconButton-root) {
				margin: -12px 0 -12px -12px;
			}

			.name {
				font-size: .8rem;

				font-weight: 600;
			}

			.progressLabel {
				display: inline-block;
				margin-left: .5rem;
			
				font-weight: 300;
				font-size: .6rem;

				white-space: nowrap;
			}

			
			// align-self: flex-start;
		}
	}

	.practiceBar {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		// background-color: #7951A9;
		// background-color: rgba(0,0,0,0.25);
		// color: rgba(0,0,0,0.9);

		// background: darken(rgb(255,250,255), 1%);
		background: $highlight-color;
		// color: rgba(0, 0, 0, 0.54);
		color: white;

		svg {
			color: white;
		}

		button[disabled] {
			opacity: 0.33;
		}
		
		box-shadow: 0 0 30px rgba(0,0,0,0.2);

		padding: .5rem 0;
		// opacity: 0.7;
		// margin-top: 5rem;
		// margin-bottom: 1rem;

		// font-size: 1rem;
		// margin: .5rem .125rem;

		.wrap {
			position: relative;

			// .stopBtn {
			// 	position: absolute;
			// 	left: .625rem;
			// 	top: -.125rem;
			// }

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			padding: 0 .125rem;

			.playBtn {
				// font-size: 2rem;
				svg {
					margin: .125rem;
					transform: scale(1.75);
				}
			}
		}

		&.hasAttemptResults {
			transform: translateY(+5rem);
		}
	}

	.packName,
	.practiceBar {
		// opacity: 1;
		transform: translateY(0);
		// pointer-events: all;
		transition: transform $default-transition-time $my-cubic-transition;

		@media screen and (max-height: 400px) {
			&.packName {
				transform: translateY(-5rem);
			}
			&.practiceBar {
				transform: translateY(+5rem);
			}
		}
	}

	.pack {
		position: relative;
		align-self: center;
		
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: center;
		// flex-wrap: wrap;

		width: 100%;

		// .progress {
		// 	font-weight: 500;
		// 	opacity: 0.7;
		// 	margin-bottom: 4rem;
		// }

		.stats {
			max-width: 20rem;
			margin-bottom: .75rem;
		}
	}


	.results {
		display: flex;
		flex-direction: column;
		align-items: center;

		margin: 2rem;

		// opacity: 0;
		// &.visible {
		// 	@include transition-all(0.67);
		// 	opacity: 1;
		// }

		background: rgba(255,0,0,0.25);
		border-radius: 1rem;
		padding: 1rem;

		&.isSuccess {
			background: rgba(0,255,0,0.25);
		}
		
		span {
			font-size: 2rem;
		}

		label {
			margin: .5rem 2rem;

			opacity: 0.8;
			text-align: center;

			b, i {
				display: block;
			}

			i {
				margin-top: .5rem;
			}
		}
	}

	.finalSummary  {
		color: $foreground-color;
		h1, h2, h3 {
			margin: 0;
			text-align: center;
		}

		.pointsUp {
			.value {
				color: lighten($highlight-color, 10%);
			}
		}

		h4 {
			opacity: 0.6;
			margin: 1rem;
			text-align: center;
		}

		.affirm {
			margin: 0;
			line-height: 1;
			margin: 1rem;
			text-align: center;
		}
	}

	.finalSummary {
		// margin: 5rem 2rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100vh;
		
		h1 {
			font-size: 1.75rem;
			color: #7951A9; //rgba(0,255,0,0.5);
			line-height: 1;
			font-weight: 700;
			letter-spacing: -0.1px;
		}
		h2, h3 {
			font-size: 1rem;
			opacity: 0.8;
			margin-top: .55rem;
			font-weight: 600;
		}

		h2 {
			margin-top: 1rem;
		}
		h3:last-of-type {
			margin-bottom: 1rem;
		}

		.exit {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			text-transform: none;
			line-height: 1;

			background: #7951A9; 
			//rgba(255,255,255,0.125);

			padding: 12px 2rem;
			margin: 2rem auto;
			border-radius: .5rem;

			color: white;
			text-decoration: none;

			:global(.MuiSvgIcon-root) {
				// margin-right: -.5rem;
				// margin-left: .5rem;
			}
		}
	}

	.stage {
		position: fixed;
		top: 2.25rem;
		left: 0;
		right: 0;
		bottom: 3.25rem;

		width: 100%;
		max-width: 640px;
		margin: auto;

		-webkit-perspective: 800;
	
		.stageCard {
			width: 100%;
			height: 100%;
			// transform-style: preserve-3d;
			transition: transform 0.33s cubic-bezier(0.55, 0.085, 0.68, 0.53);

			.cardFace {
				width: 100%;
				height: 100%;

				position: absolute;
				backface-visibility: hidden;
				transition: 0.5s;
				z-index: 2;

				&.front {
					z-index: 1;
				}

				&.back {
					transform: rotatey(-180deg);
				}
			}
		}

		&:hover {
			.cardFace.front {
				transform: rotatey(180deg);
			}

			.cardFace.back {
				transform: rotatey(0);
			}
		}
	}
}


.SuccessOrFailWidget {
	@include transition-all(.15s);

	opacity: 1;

	&.hidden {
		opacity: 0;
	}	

	position: fixed;
	top: 0;
	left: 0;
	right:  0;
	bottom: 0;

	z-index: 3000;

	// "success" mode just flash green,
	// but allow pointer events
	background: rgba(0,255,0,0.2);
	pointer-events: none;

	&.fail {
		// background: rgba(255,0,0,0.99);
		background: transparentize(#B53921, 0.01);

		pointer-events: all;
	}

	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;

	overflow: auto;
	// padding-top: 2rem;

	button {
		margin-bottom: 2rem;
	}

	.emoji {
		// margin: .5rem 0;
		padding: 2rem 0 1rem 0;
		font-size: 6.25rem;
		line-height: 1.1;
		opacity: 0.8;

		text-shadow: 0 0 24px rgba(0,0,0,0.25);
	}

	.failInfo {
		// background: rgba(255,0,0,0.99);
		border-radius: 1rem;
		padding: 1rem;
		margin: -1rem 1rem .5rem;

		// box-shadow: 0 0 24px rgba(0,0,0,0.25);

		display: flex;
		flex-direction: column;
		align-items: center;

		// label {
		// 	margin: .5rem 0rem 0;
		// 	font-size: .8rem;
			
		// 	opacity: 0.8;
		// 	text-align: center;

		// 	color: rgba(255,255,255,0.8);

		.ref, .text {
			font-weight: 700;
			color: rgba(255,255,255,1);
			line-height: 1;
		}

		.ref {
			font-weight: 300;
			font-size: .8rem;

			b {
				font-weight: 700;
			}
		}

		.text {
			margin-top: 1rem;
			font-size: 1rem;
		}

	}

	button {


		// background: rgba(255,0,0,0.99);
		// box-shadow: 0 0 24px rgba(0,0,0,0.25);

		color: white;
		padding: 1rem;
		border-radius: 1rem;
		padding: 1rem 1.5rem;

		// margin-top: 1rem;
		margin-top: -.5rem;
	}
	

	
}

// Global element in popover, hence global style
.practiceMenu {
	:global(.MuiMenuItem-root) {
		.numHint {
			display: none;

			// display: inline-block;
			font-weight: 300;
			font-size: 0.625rem;
			opacity: 0.625;
			margin: 0 .5rem 0 0;
		}

		.done {
			display: inline-block;
			font-weight: 300;
			font-size: 0.625rem;
			opacity: 0.625;
			margin: 0 .5rem 0 0;
		}

		.verseLevel {
			position: relative;
			width: 1.25rem;
			transform: translateX(-.25rem);
		}

		&:global(.Mui-selected) {
			&, &:hover {
				background: $highlight-color;
				&:hover {
					background: lighten($highlight-color, 10%);
				}

				color: white;
				svg { 
					color: white;

					
				}

				:global(.VerseBadgeLevel-text) {
					color: rgba(255,255,255,0.8);
				}

				:global(.CircleProgress-root) {
					// color: white;

					circle:not(:global(.CircleProgress-bar)) {
						stroke: rgba(255,255,255,0.5);
					}
				}

			}
		}
	}
}

body:global(.notched) {
	.packName {
		padding-top: $notch-size + .5rem;
	}

	.practiceBar {
		padding-bottom: $notch-size * .75;
	}
}

