.stats {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;

	font-size: .5rem;
	z-index: 1;
	background: rgba(0, 0, 0, 0.08);
	padding: .25rem;
	color: white;
	
	[data-stat] {
		display: flex;
		flex-direction: column;
		align-items: center;

		line-height: .9;

		color: rgba(0,0,0,0.5);

		margin: 0 .5rem 0 0;
		
		label,
		span {
			margin: 0;
			padding: 0;
			white-space: nowrap;
		}
		span {
			font-weight: 600;
			letter-spacing: -.2px;
			font-size: .5rem;
		}
		label {
			font-size: .4rem;
			letter-spacing: -.2px;
		}
	}
}