@import '../pages/styles/layoutCommon.scss';

.VerseProgressDialog {
	:global(.MuiDialogTitle-root) {
		padding-bottom: 0;
	}

	.title {
		padding: .75rem 2.5rem .5rem;

		.badgeLevel {
			transform: scale(1);
			position: absolute;
			left: 1rem;
			top: 1rem;
			width: 32px;
			height: 32px;
			// position: relative;
			// display: inline-block;
		}
	}
	
	.verseText {
		font-family: $serif-font;
		font-size: .825rem;
		line-height: 1.25;
		margin: 0;

		// border: 1px solid $highlight-color;
		background: darken($highlight-color, 22.5%);
		border-radius: .125rem;
		padding: 1rem;

		position: relative;
		@include customIgOverlayLight();

		color: white;

		// Modify customIgOverlayLight mixin
		&::after {
			position: absolute;
			opacity: 0.33;
			z-index: 100;
		}

		// Make the overlay brighter for verses
		&.verse::after {
			opacity: 0.625;
			z-index: 100;
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	
		// .practiceBtn {
			// margin: .75rem 0 .625rem;
		// }

		margin: .5rem 0 .75rem;

		button {
			font-size: .75rem;
			// line-height: 1;
			// border-radius: 0;
			text-transform: none;
			flex: 1;

			// color: lighten($highlight-color, 10%) !important;
			color: white;

			svg {
				font-size: .75rem;
				margin: 0 .125rem 0 -.5rem;
				// transform: translateY(1px);
			}

			&:global(.MuiButton-containedPrimary) {
				background: $highlight-color;
			}

			margin-right: .25rem;
			& + button {
				margin-left: .25rem;
				margin-right: 0;
			}
		}



		// $radius: .125rem;

		// .practiceBtn {
		// 	border-top-left-radius: $radius;
		// 	border-bottom-left-radius: $radius;
		// 	// padding-left: 1rem;
		// }

		// .addToPackBtn {
		// 	border-top-right-radius: $radius;
		// 	border-bottom-right-radius: $radius;
		// 	// padding-right: 1rem;
		// }
	
	}
	
	.info {
		line-height: 1.1;
		font-size: .625rem;
		opacity: 0.75;
	}

	.shareHeader {
		// padding: 1rem 24px .25rem; // match <DialogContent>
		line-height: 1.1;
		font-size: .825rem;

		display: flex;
		flex-direction: column;
		justify-content: center;
		
		p {
			margin: 0;
		}
	}

	.headerInfo {
		max-width: 560px;
		width: 100%;
	
		// position: absolute;
		z-index: 99;
		// color: white;
		// opacity: 0;
		// top: 8rem;
		// transform: translateY(-100vh);
		transition: opacity $default-transition-time $my-cubic-transition,
			transform $default-transition-time $my-cubic-transition;



		.circles {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 1.5rem;

			.progressCircle {
				:global(.CircleProgress-bar) {
					// stroke: lighten(white, 20%);
					stroke: lighten(#c35256, 20%);
				}
			}

			.badgeCircle {
				:global(.CircleProgress-bar) {
					stroke: lighten(#5b822b, 20%);
				}
			}

			.block {
				width: 50%;

				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				position: relative;

				padding: 48px .125rem 0;

				:global(.CircleProgress-root) {
					top: 16px;
					left: 50%;
				}

				.info {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;

					label {
						margin: 0;
						font-size: 0.625rem;
						line-height: 1.1;
						font-weight: 600;

						text-align: center;

						&.hint {
							font-weight: normal;
							opacity: 0.62;
							font-size: 0.5rem;
							margin-top: .125rem;
							height: 1.75rem;
						}
					}

					.data {
						font-weight: 600;
						font-size: 0.87rem;
						margin: .5rem 0 .25rem;
					}

					.dataSubtext {
						opacity: 0.7;
						font-size: 0.5rem;
						text-align: center;
					}
				}

				&.badgeLevel {
					.data {
						.value {
							color: lighten(#5b822b, 20%);
						}
					}
				}

				&.progressPercent {
					.data {
						.value {
							// color: white;
							color: lighten(#c35256, 20%);
							opacity: 1;
						}
					}
				}
			}


		}
	}
}