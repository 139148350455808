@import '../pages/styles/common.scss';

$highlightColor: #7951A9;

.ChoosePackDialog {
	.MaybeTryDownloading {
		button {
			text-transform: none;
			line-height: 1.1;
			width: 100%;
			padding: .5rem;
			letter-spacing: -0.1px;
		}
	}

	.searchBar {
		padding-top: 1.25rem;

		h2 {
			display: flex;
			flex-direction: row;
		}

		.search {
			margin-right: .25rem;
		}

		.newBtn {
			font-size: .875rem;
			text-transform: none;
			margin-right: -.25rem;

			svg {
				font-size: .875rem;
			}
		}

		.versionSelect  {
			// transform: translateY(1px);
			width: 6rem;

			:global(.MuiSelect-root.MuiInput-input) {
				font-size: .75rem;
			}

			:global(.MuiSelect-select):global(.MuiSelect-select) {
				padding-left: 4px;
			}

			:global(.MuiSelect-icon) {
				top: calc(50% - 18px);
				right: -4px;
			}
		}

		// border-bottom: 1px solid rgba(0,0,0,0.125);
		&.hasResults {
			padding-top: 1rem;
			box-shadow: 0 0 30px rgba(0,0,0,0.5);
		}
	}

	:global(.MuiDialogActions-root) {
		justify-content: space-between;
		padding-left: 1rem;

		.summary {
			opacity: 0.8;
		}
	}

	.noMatch {
		line-height: 1.1;
	}

	.results {
		padding: .25rem 0 .5rem 0;
		// margin-top: -.75rem;

		$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) .75rem,
			rgba(0, 0, 0, 1) 95%,
			rgba(0, 0, 0, 0) 100%);


		mask-image: $mask;
		-webkit-mask-image: $mask;

		.resultsList {
			.searchResult {
				font-size: .825rem;

				padding: .33rem .75rem .33rem 1rem;

				transition-delay: 0;

				.resultWrap {
					line-height: 1.1;

					.partHighlight {
						// background: rgba(255,255,0,0.25);
						background: transparentize($highlightColor, .75);
						// color: white;
					}

					.ref {
						background: rgba(0,0,0,0.5);
						color: white;
						border-radius: 0.125rem;
						padding: 0 .125rem;
						margin-right: .125rem;

						opacity: 0.75;
						// font-weight: 600;
						// padding: 0 .5rem 0 .75rem;

						.partHighlight {
							// background: rgba(255,255,0,0.25);
							background: transparentize($highlightColor, .25);
							// color: white;
						}
					}

					.text {
						// font-family: $serif-font; // from common.scss
						// color: black;
						// color: $foreground-color;
						opacity: 1;
					}
				}



				&:nth-child(even) {
					background: rgba(0,0,0,0.075);
				}


				&.active {
					// background: rgba(255,255,0,0.5);
					// color: white;
					background: $highlightColor;
					color: white;

					.partHighlight {
						background: rgba(255,255,255,0.33);
					}

					.ref {
						.partHighlight {
							// background: rgba(255,255,0,0.25);
							background: transparentize(lighten($highlightColor, 20%), .25)
							// color: white;
						}
					}

					.text {
						color: white;
					}
				}

			}
		}
	}

}
