@import '../styles/layoutCommon.scss';

.content {
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// justify-content: flex-start;
	// height: 100%;
	// width: 100%;

	// background: no-repeat center center fixed; 
	// background-size: cover;

	// color: white;

	margin: 0;

	color: $foreground-color;

	// position: relative;
	// height: 100%;
	// min-height: 100%;

	// position: fixed;
	// top: 2.25rem;
	// left: 0;
	// right: 0;
	// bottom: 3.5rem;

	transition: bottom $default-transition-time $my-cubic-transition,
		top $default-transition-time $my-cubic-transition;

	width: 100%;
	max-width: 640px;
	margin: auto;

	overflow-x: hidden;
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	align-items: center;
}