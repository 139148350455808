.circles {
	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
		font-size: 10px; // using px because circle size is in px
		font-weight: bold;
		color: #5b822b;
		font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	}

	:global(.CircleProgress-root) {
		box-shadow: none;

		circle {
			stroke: rgba(0,0,0,0.125);
		}

		:global(.CircleProgress-bar) {
			stroke-width: 3px;
		}
	}

	.progressCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(#c35256, 20%);
		}
	}

	.badgeCircle {
		:global(.CircleProgress-bar) {
			stroke: lighten(#5b822b, 20%);
		}
	}

	&.hasStar {
		.progressCircle,
		.badgeCircle {
			display: none;
		}

		.text {
			background: white;
			border-radius: 50%;

			$x: 1.21rem;
			width:  $x;
			height: $x;
			overflow: hidden;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
}