@import '../styles/layoutCommon.scss';

.root {
	position: relative;
	margin-top: .5rem;

	width: 100%;

	// background-color: rgba(255,255,255,0.5) !important;

	box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 
				0px 6px 10px 0px rgba(0,0,0,0.2), 
				0px 1px 18px 0px rgba(0,0,0,0.15) !important;

    &.match640 {
		// background-color: rgba(255,255,255,0.75);

		box-shadow: 0 2px 4px -2px rgba(0,0,0,0.24), 
					0 4px 24px -2px rgba(0, 0, 0, 0.2) !important;
	}

	width: 100%;
	max-width: 640px;

	.ref {
		font-size: .8rem;
		font-weight: 600;
		opacity: 0.75;
		margin: .625rem 0 .25rem;
	}

	.text {
		line-height: 1.1;
		font-family: $serif-font;
	}

	:global(.MuiCardContent-root) {
		position: relative;
		
		&, &:last-child {
			padding: 0rem 1rem .75rem;
		}
	}
	
	&.hasStats {
		:global(.MuiCardContent-root) {
			&, &:last-child {
				padding: 0rem 1rem 1.825rem;
			}
		}
	}

	.circles {
		position: absolute;
		top: 1.2rem;
		right: 2.7rem;
	}
}