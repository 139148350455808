@import '../styles/layoutCommon.scss';

.root {

	color: $foreground-color;
	min-height: 100vh;

	max-width: 560px;
	width: 100%;

	margin: 0 auto;

	padding: 1rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	:global(.SimpleBanner-root) {
		margin: 0 auto;
	}

	.loginError {
		display: block;
		text-align: center;
		color: red;
		margin: 1rem  0 0;
		word-break: break-all;
	}
	
	.connectFb {
		margin: .75rem 0 .75rem;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		button {
			width: 100%;
			// max-width: calc(100% - 2rem);

			background: #7951A9;
			color: white;
			padding: 0.25rem .75rem;

			text-align: left;
			text-transform: none;

			position: relative;
			align-items: center;
			justify-content: flex-start;

			svg {
				margin-right: .6rem;
			}

		}

		button.fb {
			// margin: 1.5rem 1rem .5rem;
			margin: 0 0 .25rem;

			@include fb-btn-overrides();
		}

		button.apple {
			margin: 0 0 .25rem 0;

			background: white;
			color: black !important;
			
			svg {
				fill: black !important;
				transform: translateY(-.1rem);
			}
		}

		p {
			margin: .75rem 0 0 0;
			font-size: .625rem;
			opacity: 0.8;
			line-height: 1.1;
			text-align: center;

			width: 100%;

		}


	}
}