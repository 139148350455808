@import '../pages/styles/common.scss';

$navHeight: 3.5rem;

.root {
	@include transition-all();

	opacity: 0;
	pointer-events: none;
	&.visible {
		pointer-events: all;
		opacity: 1;
	}

	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	top: auto;

	z-index: 999;
	// background: $foreground-color;

	&:global(.MuiBottomNavigation-root) {
		height: $navHeight;
		padding: .125rem 0 0 ;

		// background: transparentize($foreground-color, .8);
		// background: darken(rgb(255,250,255), 1%);
		background: rgba(0,0,0,0.9);

		box-shadow: 0 0 30px rgba(0,0,0,0.8);

		button {
			outline: none;
		}

		:global(.MuiBottomNavigationAction-root) {
			color: $foreground-color;
		}

		:global(.MuiBadge-badge):global(.MuiBadge-anchorOriginTopRightRectangle) {
			top: .25rem;
		}

		:global(.MuiBottomNavigationAction-root):global(.Mui-selected) {
			// color: #AC2A9B;
			color: lighten($highlight-color, 10%);
		}
	}	
}

body:global(.PrimaryNav-visible) {
	padding-bottom: $navHeight;
}


body:global(.notched) {
	.root {
		padding-bottom:   $notch-size * .75;
		height: 3.25rem + $notch-size * .75;
	}
}

body:global(.native) {

	.root:global(.MuiBottomNavigation-root) {
		:global(.MuiBottomNavigationAction-root) {
			transform: translateY(.125rem);
		}

		:global(.MuiSvgIcon-root) {
			font-size: 1.25rem;
		}

		:global(.MuiBottomNavigationAction-label) {
			font-size: .625rem;

			&:global(.Mui-selected) {
				font-size: .75rem;
			}
		}
	}
}