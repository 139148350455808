@import '../styles/layoutCommon.scss';

// .backgroundSwatch {
// 	color: black;
// 	height: 100%;
// }

.root {
	color: $foreground-color;

	& {
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		// height: 100%;
		// width: 100%;

		background: no-repeat center center fixed; 
		background-size: cover;

		.centeringBlock {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}

	&.rootWelcomeMode {
		justify-content: center;

		.footerLinks {
			padding-bottom: 0 !important;
		}
	}
	

	
	.buttons {
		// display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0 1rem;
		max-width: 15rem;

		
	}

	.settings {
		color: white;
		position: fixed;
		z-index: 10;
		top: 1rem;
		right: 1rem;

		background: transparent;
		border: none;

		@include transition-all();

		svg {
			font-size: 1rem;
		}

		&, &:active, &:hover {
			outline: none;
			text-decoration: none;
		}

		&:hover, &:active {
			svg {
				filter: drop-shadow(0 0 10px rgba(0,0,0,0.75px));
			}
		}
	}

	.infoLink {
		margin-top: 1rem;
		color: rgba(255,255,255,0.77);
	}
	
	.sosMode {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0 1rem;
		max-width: 15rem;

		h1, h2 {
			color: white;
			font-size: 3.5rem;
			text-align: center;

			color: white;
			font-weight: bold;
			width: 100%;
			// background: #C34A36;
			background: #C34A36;
			// border:  .2rem solid white;
			padding: 2rem 0;
			border-radius: .75rem;

			opacity: 1;
   			animation: fade 2s ease-in-out infinite;
		}

		h2 {
			animation: none;
		}

		
	}
}

.bigButton, 
// We do this weird join to increase specificity of our selector to make sure 
// we get priority over the styles in .MuiButtonBase-root
:global(.MuiButtonBase-root).bigButton {
	color: white;
	font-weight: 500;
	// border: .2rem solid white;
	border: 0;
	// border-radius: 1.25rem;
	// border-radius: .75rem; // set at bottom now
	padding: 1rem 2rem;
	width: 100%;
	text-align: center;
	background: rgba(0,0,0,0.43);

	@include transition-all(.125s);
	@include noselect();


	display: flex;
	flex-direction: column;
	align-items: center;

	// These two from https://mycolor.space/?hex=%23845EC2&sub=1
	// ref Classy Pallette
	&.bigTimerButton {
		// background: #845EC2;
	}

	&.bigSosButton {
		background: #C34A36;
	}

	& + .bigButton {
		margin: 1.5rem 0rem 0;
	}

	&:first-of-type {
		margin-top: .5rem;
	}

	&:last-of-type {
		margin-bottom: .5rem;
	}
	
}

button.bigButton {
	// &, &:active, &:hover {
	// 	text-decoration: none;
	// 	outline: none !important;
	// 	box-shadow: none;
	// }

	// &:hover, &:active {
	// 	// color: lighten($color: $brand-third, $amount: 67%);
	// 	// background: transparentize($color: $brand-third, $amount: .75);
	// 	// // border-color: $brand-primary;
	// 	// border-color: lighten($color: $brand-third, $amount: 65%);
	// 	box-shadow: 0 0 30px inset rgba(0,0,0,0.75);
	// 	text-shadow: 0 0 30px rgba(0,0,0,0.75);
	// 	svg {
	// 		filter: drop-shadow(0 0 30px rgba(0,0,0,0.75px));
	// 	}
	// }

	opacity: 0.9;
	&:hover, &:active {
		opacity: 1;
		background: rgba(0,0,0,0.63);
		// background: white;
		// color: black;
	}

	svg {
		margin: .5rem 0 .5rem;
		font-size: 2rem;
	}
}


.timerDraftUi, .timerRunningUi, .settingsPanel, .WelcomePanel, .OnboardingPanel, .HomePanel {
	// background: #845EC2;
	// width: calc(100% + 3.5rem);
	width: 100%;
	padding: 1rem 0;

	color: rgba(0,0,0,0.9);
	font-weight: 500;
	text-align: center;
	margin: 0 0 -1rem;

	@include transition-all(.125s);
	@include noselect();


	display: flex;
	flex-direction: column;
	align-items: center;


	// :global(.MuiListItem-root) {
	.myListItem {
		// default for mui list items is flex-start
		justify-content: space-between;
		display: flex; // for use with subheader
		flex-direction: row;  // for use with subheader
		align-items: center;  // for use with subheader
	}

	label {
		line-height: 1.1;
	}

	.timeSelection {
		display: flex;
		flex-direction: row;

		margin-bottom: .5rem;

		.input-group:first-of-type {
			// margin-bottom: .5rem;
			margin-right: 0.5rem;
		}

		.input-group-text {
			width: 3rem;
			// font-size: .7rem;
		}

		// select {
		// 	// font-size: .7rem;

		// 	// thanks to https://stackoverflow.com/a/43919388
		// 	text-align-last: right;

		// 	option {
		// 		direction: rtl;
		// 	}
		// }
	}

	.destinationBox,
	.noteBox {
		margin-top: .5rem;
	}

	.smallButton {
		margin: 1rem 0;
	}

	.extraButtons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.smallButton {
			flex-direction: column;
			margin-bottom: 0;

			&:first-of-type {
				margin-right: .5rem;
			}
		}
	}

	.draftButtons {
		.smallButton {
			&:last-of-type {
				margin-bottom: 0rem;
			}
		}
	}
}


// Used for sosDangerZone
@keyframes fade {
	0%,100% { opacity: 0 }
	50% { opacity: 1 }
  }

.timerRunningUi {
	.timerDisplay {
		font-size: 2.75rem;
		font-family: 'Roboto Mono', monospace;
		// font-family: 'Roboto', sans-serif;

		&.sosDangerZone {
			color: white;
			font-weight: bold;
			width: 100%;
			// background: #C34A36;
			background: #D65DB1;
			// border:  .2rem solid white;
			border-radius: .33rem;
			margin:  .5rem 0;

			opacity: 1;
   			animation: fade .67s ease-in-out infinite;
		}
	}

	:global(.MuiButtonBase-root).smallButton,	
	.smallButton {
		// flex-direction: column;
		// margin-bottom: .5rem;
		text-align: left;
		justify-content: flex-start;
		svg {
			margin: 0 .25rem 0 0rem;
		}

		&:first-of-type {
			margin-top: 1.25rem;
		}
	}

	.cancelTimerButton {
		margin:0 0 -.5rem;
	}
}

.settingsPanel, .OnboardingPanel {
	max-width: 560px;

	.banner {
		margin-top: 1.5rem;
	}

	svg {
		color: white;
	}

	:global(.MuiButtonBase-root) {
		svg {
			color: rgba(255,255,255,0.77);
		}
	}

	:global(.MuiFab-label) {
		svg {
			// color: rgba(0,0,0,0.77);
			color: #90335a;
		}
	}

	.settingsFields {
		width: 100%;

		display: flex;
		flex-direction: column;
		text-align: left;
		// padding: .5rem;

		label {
			margin-top: .5rem;
			margin-bottom: 0rem;
		}

		.contacts {
			// padding: 0 16px; // match material ui for settings panel padding
			label {
				margin-top: -1rem;
				padding: 0 16px; // match material ui for settings panel padding
				font-size: .875rem; // mui body2
			}
		}

		.table {
			width: 100%;
			margin: 0.125rem 0 0;
			border: 0.2rem solid white;
			background: rgba(0,0,0,0.33);

			// MUI compat
			width: calc(100% + .4rem); // hide borders on both sides of screen
			margin-left: -.2rem; // compensate for extra width
			
			td, th {
				color: white;
				// padding: 0.5rem;
				padding: 4px 16px; // mui compat
				// font-size: 0.75rem;
				border-color: rgba(255,255,255,0.77);
			}

			th {
				background: rgba(0,0,0,0.33);
			}

			//
			// Don't need these 0 paddings unless we remove the border
			//
			// td:first-child,
			// th:first-child {
			// 	padding-left: 0;
			// }

			// td:last-child,
			// th:last-child {
			// 	padding-right: 0;
			// }
			
			td {
				@include force-word-break();
			}
		}

		.addNewContact {
			margin-top: 0;
			border-top: 0;

			// MUI compat
			width: calc(100% + .4rem); // hide borders on both sides of screen
			margin-left: -.2rem; // compensate for extra width
			padding: 16px; // mui compat
		}
	}

	.welcomeFinalPage {
		margin: 1.7rem 0 1.5rem;

		p {
			margin: 0;
			text-align: center;
		}
	}

	.versionInfo {
		text-align: center;
		padding: 1rem 0;
	}
}

.progressDots {
	display: flex;
	flex-direction: rows;

	margin: .5rem 0;

	.dot {
		border: .125rem solid white;
		background: rgba(0,0,0,0.33);
		width: 1.5rem;
		height: 1.5rem;
		
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		border-radius: 50%;

		margin-right: .5rem;
		&:last-child {
			margin-right: 0;
		}

		&.filled {
			background: white;
			color: rgba(0,0,0,0.33);
		}

		.text {
			font-size: 0.6rem;
		}
	}
}

.OnboardingPanel, .WelcomePanel {
	padding: 1.5rem;
	font-weight: normal;
	max-width: 720px;


	h2 {
		text-align: center;
		font-size: 1rem;

		
	}
	p {
		font-size: 0.8rem;
		line-height: 1.1;
		padding: 0 1rem;
		margin: 0 0 0.5rem;
		// text-align: justify;
		text-align: left;

		// w// padding: .5rem;
	}

	.settingsFields {
		text-align: left;

		label {
			margin-top: .125rem;
		}

	}

	.smallButton {
		margin: 0.5rem 0;
		align-items: center;
		justify-content: center;
	}
}

.WelcomePanel {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	.banner {
		margin-top: 0;//2.25rem;
	}
	.intro {
		color: $foreground-color;
		padding: 1rem;

		p {
			margin: 0 0;
			padding: 0;
			// text-align: justify;
			text-align: left;
			// text-align: center;
		}

		p + p {
			margin-top: 0.5rem;
		}
	}

	.smallButton::last-of-type {
		margin-bottom: 0;
	}
}

.smallButton, .swalButton,
:global(.MuiButtonBase-root).smallButton,
:global(.MuiButtonBase-root).swalButton {
	color: white;
	font-weight: 500;
	// border: .2rem solid white;
	border: 0;
	// border-radius: 1.25rem;
	// border-radius: .25rem; // set at bottom now
	padding: .5rem .5rem;
	width: 100%;
	margin: .5rem 0rem;
	
	text-align: center;
	background: rgba(0,0,0,0.33);

	&.swalButton {
		border-color: rgba(0,0,0,0.5);
		margin: .5rem 0;
	}

	@include transition-all(.125s);
	@include noselect();


	display: flex;
	flex-direction: row;
	align-items: center;

	&, &:active, &:hover {
		text-decoration: none;
		outline: none;
	}

	// &:hover, &:active, &.active {
	// 	// color: lighten($color: $brand-third, $amount: 67%);
	// 	// background: transparentize($color: $brand-third, $amount: .75);
	// 	// // border-color: $brand-primary;
	// 	// border-color: lighten($color: $brand-third, $amount: 65%);
	// 	box-shadow: 0 0 30px inset rgba(0,0,0,0.75);
	// 	text-shadow: 0 0 30px rgba(0,0,0,0.75);
	// 	svg {
	// 		filter: drop-shadow(0 0 30px rgba(0,0,0,0.75px));
	// 	}
	// }

	svg {
		// margin: .25rem 0 .25rem;
		margin: 0 .5rem 0 .25rem;
		font-size: 1.25rem;
	}

	.simpleSpinner {
		margin-right: .5rem;
	}

}

.swalFields {
	label {
		margin: 0.5rem 0 0;
	}
}

.text-muted, :global(.MuiListSubheader-root), :global(.MuiTypography-colorTextSecondary) {
	color: rgba(0,0,0,0.77) !important;
}

small {
	font-size: 0.625rem;
	line-height: 1;
}

small.text-muted {
	margin-bottom: .5rem;
}

// These two from https://mycolor.space/?hex=%23845EC2&sub=1
// ref Classy Pallette
.timerBackground,
:global(.MuiButtonBase-root).timerBackground {
	background: #845EC2;
}

.sosBackground,
:global(.MuiButtonBase-root).sosBackground {
	background: #FFFAFF;
}

// Same page as above, just last in "Matching Gradient"
.greenBackground,
:global(.MuiButtonBase-root).greenBackground {
	// background: #008F7A;
	background: $highlight-color;
}

.HomePanel {
	padding: 0;

	.banner {
		margin: 2.5rem 0 0rem;
	}

	.bigTimerButton {
		margin-top: 1.5rem;
	}

	.footerLinks {
		margin: 1.5rem 0;
		padding-bottom: 2.5rem;
		font-size: 0.8rem;
		display: flex;
		flex-direction: row;
		justify-content: center;

		a {
			display: block;
			margin: 0 0.5rem;
		}
	}
}

.simpleSpinner {
	svg {
		margin: 0 !important;
	}
}

button:global(.MuiButtonBase-root) {
	&:active,
	&:focus {
		outline: none;
	}
}

:global(.MuiListItem-root) {
	:global(.MuiIconButton-root) {
		// padding: 0 12px;
		margin: -12px .5rem;

		svg {
			font-size: 1rem;
			// margin: -12px .5rem;
		}
	}
}

.swalButton,
.bigButton,
.smallButton,
:global(.MuiButtonBase-root).smallButton,
:global(.MuiButtonBase-root).bigButton,
:global(.MuiButtonBase-root).swalButton,
.form-control, 
.custom-select {
	border-radius: .33rem;
}

.rootWelcomeMode {
	:global(.MuiButtonBase-root).bigButton,
	.bigButton {
		border-radius: .75rem;
	}

	&.timerActive {
		:global(.MuiButtonBase-root).bigButton,
		.bigButton {
			border-radius: .33rem;
		}
	}
}

.swalButton.contactImportButton {
	background: transparent;
	// color: rgba(0,0,0,0.63);
	color: #FFFAFF;
	text-align: left;
	font-size: 0.85rem;
	padding: 0;

	margin: 0;

	svg {
		margin-left: -.125rem;
	}
}

.nativeContactsImportList {
	:global(.MuiListItemText-root),
	:global(.MuiTypography-root)	{
		line-height: .8;
	}	

	&:global(.MuiList-root) {
		padding: 0;
		&> div  {
			width: 100% !important;
			$mask: linear-gradient(to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) .5rem,
			rgba(0, 0, 0, 1) 97.5%,
			rgba(0, 0, 0, 0) 100%);

			mask-image: $mask;
			-webkit-mask-image: $mask;
		}
	}
}

.appStoreLinks {
	label {
		font-size: .8rem;
	}

	a {
		img {
			height: 48px;

			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%);
			  
			&:hover {
				filter: none;
				-webkit-filter: none;
			}
		}

		&:first-of-type {
			margin-right: .5rem;
		}
	}
}

:global(.swal2-content) b {
	font-weight: bold
}

.banner {
	@include noselect();

	// larger margin at top since nolonger "flex centering" stuff on page due to changing height of timer box
	margin: 3.5rem 1rem 0rem;
	// border-bottom: .25rem solid white;
	font-size: 2rem;
	font-weight: 700;
	letter-spacing: -1px;

	.d {
		color: rgba(157,136,178,1);
	}

	.ecidr {
		color: rgba(157,136,178,0.75);
	}
}
