@import '../styles/layoutCommon.scss';

.root {
	position: relative;
	z-index: 0;
	// $foreground-color: rgba(0,0,0,0.9);
	$foreground-color: rgba(255,255,255,0.9);
	color: $foreground-color;

	max-width: 640px;
	width: 100%;
	// The 1rem is a bugfix for WKWebView in iOS
	min-height: calc(100vh + 1rem);

	margin: 0 auto;

	// padding-bottom: 4rem;
	padding-bottom: 0rem;
	margin-bottom: 9rem;
	

	// @include transition-all(.125s);
	@include noselect();

	:global(.MuiInputLabel-root) {
		margin: .5rem 0 0 .35rem;
		font-size: .75rem;
		color: $foreground-color;
		opacity: 0.75;

		&:first-of-type {
			margin-top: 1.5rem;
		}

		& + :global(.MuiFormHelperText-root) {
			font-size: .45rem;
			margin: 0 0 -.1rem .35rem;
			line-height: 1;
			color: $foreground-color;
			opacity: 0.625;
		}
	}

	.newPackBtn {
		position: fixed;
		right:  0.5rem;
		bottom: 4rem;

		background: $highlight-color;
		color: white;

		width: 2.5rem;
		height: 2.5rem;
	}

	.greeting {
		font-size: 1.5rem;
		margin: 1.25rem 1rem 1.5rem .4rem;
		line-height: 0.95;
		font-family: $serif-font;

		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		padding-right: 1.25rem;

		.settingsLink {
			opacity: 0.7;
			position: absolute;

			// top: 50%;
			// right: 0;
			top: .75rem;
			right: -.75rem;
			transform: translate(0, -50%);

			color: $foreground-color;

			// &.alertPresent {
			// 	top: 3.625rem;
			// }

			svg {
				transform: scale(.75);
			}
		}

		.greetingCircles {
			display: inline-block;
			width: 32px;
			height: 32px;
			border-radius: 50%;

			transform: translateY(-3px);


			:global(.CircleProgress-root) {
				:global(.CircleProgress-bar) {
					stroke-width: 2px;
				}
			}

			.timeCircle {
				:global(.CircleProgress-bar) {
					stroke: lighten(#5b822b, 20%);
				}
			}
		
			.verseCircle {
				:global(.CircleProgress-bar) {
					stroke: white;
				}
			}
		}

		.circles {
			position: relative;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			
			.letter {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 1rem;
			}

			.date {
				position: absolute;
				font-size: .5rem;
				top: -.35rem;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0.75;
			}

			.data {
				position: absolute;
				bottom: -.625rem;
				left: 50%;
				transform: translateX(-52.5%);

				font-size: .5rem;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				.minutes {
					color: lighten(#e0bc41, 1%);
				}

				.verses {
					color: lighten(#c35256, 1%);
				}

				.val {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
					text-align: center;

					.num {
						font-weight: 600;
						font-size: .6rem;
					}

					.label {
						font-weight: 300;
						font-size: .4rem;
					}
				}

				.val + .val {
					border-left:  1px solid rgba(255,255,255,0.25);
					margin-left:  .5rem;
					padding-left: .5rem;
				}
			}

			:global(.CircleProgress-root) {
				:global(.CircleProgress-bar) {
					stroke-width: 3px;
				}
			}

			.timeCircle {
				:global(.CircleProgress-bar) {
					stroke: lighten(#e0bc41, 1%);
				}
			}
		
			.verseCircle {
				:global(.CircleProgress-bar) {
					stroke: lighten(#c35256, 1%);
				}
			}
		}
	}

	.mainPoints {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		margin: 0rem .25rem .125rem .4rem;

		font-size: .625rem;
		opacity: 0.8;
		line-height: 1;

		.data {
			font-weight: 600;
			font-size: .825rem;
			// margin: 0 0 -.25rem;

			margin: -0.05rem .25rem  0 0;

			.value {
				color: lighten($highlight-color, 10%);
			}
		}

		.dataSubtext {
			opacity: 1;
			// opacity: 0.7;
			// font-size: 0.5rem;
			// text-align: center;
			// margin-top: .5rem;

			// font-size: 0.625rem;
			// line-height: 1.1;
			// font-weight: 600;
		}

		.pointsUp {
			margin-left: .25rem;

			.points {
				font-weight: 600;
				color: lighten($highlight-color, 10%);
			}
		}
	}

	.streak {
		margin: .125rem .25rem .125rem .4rem;
		font-size: .625rem;
		opacity: 0.8;
		line-height: 1;
	}

	.emptyCta {
		opacity: 0.7;
		font-size: 0.75rem;
		line-height: 1;
		text-align: center;
		margin: 1rem;

		.ctaButtons {
			margin-top: 1rem;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			button {
				border-radius: 1rem;

				font-size: .75rem;

				background: $highlight-color;
			}

			button:first-of-type {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				// padding-right: .25rem;
			}

			button:last-of-type {
				padding-left: .25rem;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	.wrap {
		display: flex;
		flex-direction: row;
		// flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		
		flex-wrap: wrap;

		padding: .125rem;

		// Space under nav bar
		&:last-of-type {
			margin-bottom: 4rem;
		}

		.item {
			// box-sizing: border-box;
			// margin-bottom: .25rem;
			// margin-left: .5rem;
			// margin-right: .5rem;
			// margin: 0 .25rem .5rem .25rem;

			&:first-of-type {
				// margin-top: .25rem;
			}

		}


		@media (max-width:639px) {
			& {
				// So simple, it's stupid
				// Had to follow this: https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
				display: flex;
				flex-wrap: nowrap;
				overflow-x: auto;
				overflow-y: hidden;
		
				flex-direction: row;
				justify-content: center;
				align-items: flex-start;
		
				margin: 0;
				width: 100%;
				
				padding: 0 .125rem .125rem .125rem;
		
				-webkit-overflow-scrolling: touch;
				// &::-webkit-scrollbar {
				// 	display: none;
				// }
		
				.item {
					// The secret sauce and magic, Demo here https://codepen.io/colinlord/pen/oZNoOO
					flex: 0 0 auto;

				
				}

				&.overflows {
					justify-content: flex-start;

					.item {
						&:last-of-type {
							padding-right: 5rem;
						}
					}
				}
			}
		}

	}

	.wrapAnim {
		.item {
			transition: transform 200ms cubic-bezier(.42, 0, .58, 1);
		}

		&:focus-within .item,
		&:hover .item {
			transform: translateY(-25%);
		}

		.item:focus ~ .item,
		.item:hover ~ .item {
			transform: translateY(25%);
		}

		.item:focus,
		.item:hover {
			transform: scale(1.5);
			z-index: 1;
		}

	}

}

body:global(.notched) {
	.root {
		padding-top: $notch-size;

		.newPackBtn {
			bottom: 3.625rem + $notch-size;
		}
	}
}

body:global(.native) {
	.root {
		:global(.MuiInputLabel-root) {
			margin-top: 1.25rem;
		}
	}
}