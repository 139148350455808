@import '../pages/styles/layoutCommon.scss';

.CircleProgress {
	// transition: none;
	
	display: block;
	box-shadow: 0 0 1em rgba(0,0,0,0.33);
	border-radius: 100%;
	// position: relative;
	position: absolute;
	top: 50%;
	left: 50%;
	// Yes, 52% to better align the text vertically in the circle
	transform: translate(-50%, -50%);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	svg {
		
		circle {
			stroke-dashoffset: 0;
			transition: stroke-dashoffset 1s linear;
			// stroke: var(--pack-text2,rgba(255,255,255,0.625));
			stroke: rgba(255,255,255,0.625);
			// stroke-width: 3px;
			stroke-width: 1px;
			shape-rendering: auto; 
		}
	
		.bar {
			stroke-width: 6px;
			stroke: lighten($highlight-color, 20%);
			// stroke: darken($highlight-color, 10%);
			// stroke: $highlight-color;

			// filter: drop-shadow(0 0 3px black);
			transform-origin: center center;
			transform: rotate(-90deg);
			stroke-linecap: round;
			stroke-dashoffset: 0;
		}
	}
		
//   #cont:after {
// 	position: absolute;
// 	display: block;
// 	height: 160px;
// 	width: 160px;
// 	left: 50%;
// 	top: 50%;
// 	box-shadow: inset 0 0 1em black;
// 	content: attr(data-pct)"%";
// 	margin-top: -80px;
// 	margin-left: -80px;
// 	border-radius: 100%;
// 	line-height: 160px;
// 	font-size: 2em;
// 	text-shadow: 0 0 0.5em black;
//   }
  
}